import { AppLayoutComponent } from './../../layout/app-layout/app-layout.component';
import { Brokerage } from './../../classes/classes';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-manage-brokerage-relations',
  templateUrl: './manage-brokerage-relations.component.html',
  styles: [
  ]
})
export class ManageBrokerageRelationsComponent implements OnInit {
  @Input() set brokerages(value: Brokerage[]) {
    if (value != null && value.length != 0) {
      this._brokerages = value;
      this.currentBrokerage = this._brokerages.find(b => b.BrokerageId === this.currentBrokerageId);
      this._showAddChild = this.currentBrokerage?.ParentBrokerageId === null;
      this._showAddParent = this._brokerages.length === 1;
      this._hasChild = this.currentBrokerage?.ParentBrokerageId === null && this._brokerages.length > 1;
      this._parent = this.currentBrokerage?.ParentBrokerageId !== null ? this._brokerages.find(b => b.BrokerageId === this.currentBrokerage.ParentBrokerageId) : null;

      if (this._hasChild) {
        this._children = this._brokerages.filter(x => x.BrokerageId != this.currentBrokerageId);
      }

      this._smallScreen = this.app.smallScreen;
    }
  }
  @Input() currentBrokerageId: string;
  @Output() updateBrokerage = new EventEmitter<Brokerage>();
  currentBrokerage: Brokerage;

  _brokerages: Brokerage[] = [];
  _showAddChild: boolean = false;
  _showAddParent: boolean = false;
  _hasChild: boolean = false;
  _parent: Brokerage = null;
  _children: Brokerage[] = [];
  _smallScreen = false;

  constructor(private app: AppLayoutComponent) { }

  ngOnInit(): void {

  }

  public removeChild(brokerage: Brokerage): void {
    brokerage.ParentBrokerageId = null;
    this.updateBrokerage.emit(brokerage);
  }

  public addAsChild(brokerage: Brokerage): void {
    brokerage.ParentBrokerageId = this.currentBrokerageId;
    this.updateBrokerage.emit(brokerage);
  }

  public selectParent(brokerage: Brokerage): void {
    this.currentBrokerage.ParentBrokerageId = brokerage.BrokerageId;
    this.updateBrokerage.emit(this.currentBrokerage);
  }

  public removeParent(): void {
    this.currentBrokerage.ParentBrokerageId = null;
    this.updateBrokerage.emit(this.currentBrokerage);
  }
}

