import { Brokerage } from './../../classes/classes';
import { Component, Input, OnInit } from '@angular/core';
import { Field } from '../../classes/form';
import { Graphable, GraphConfig, GraphRelationship } from '../classes';
import mermaid from 'mermaid';
declare const $: any;

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styles: [
  ]
})
export class GraphComponent implements OnInit {
  @Input() set config(value: GraphConfig) {
    this._config = value;
    this.GenerateGraph();
  };
  @Input() currentRecordId: string = null;

  _config: GraphConfig;
  graph: string;

  constructor() { }

  ngOnInit(): void {
    mermaid.initialize({
      startOnLoad: true, securityLevel: 'loose' as any
    });
  }

  public GenerateGraph(): void {
    // We don't want to bother trying to generate a graph if there is no data
    if (this._config == null || this._config.data == [] || this._config.data == null || this._config.data.length == 0) {
      return;
    }
    // This is generated using mermaid-js
    let graph = 'flowchart LR;\n';

    let data = this._config.data;

    // Render all the nodes
    for (let i in data) {
      const record = data[i];
      const showFields = this._config.displayFields.get(record.type);

      graph += `  ${record[record.primaryKey]}["`;
      showFields.forEach(displayField => {
        graph += `${displayField.label ?? ''}${displayField.label != null ? ': ' : ''}${record[displayField.field] ?? 'No Value'}${displayField.separator ?? '\\n'}`;
      });
      graph += `"];\n`;
      if (this._config.clickForType.has(record.type)) {
        graph += `  click ${record[record.primaryKey]} ${this._config.clickForType.get(record.type)}${this._config.tooltipsForType.has(record.type) ? ' "' + this._config.tooltipsForType.get(record.type) + '"' : ''};\n`;
      }
    }

    // Find all of the links
    let links = new Map<any, any[]>();
    let fromKeys = new Array<any>();
    for (let i in this._config.relationships) {
      const relationship = this._config.relationships[i];
      const from = data.filter(x => x.type == relationship.fromType);
      from.forEach((x) => {
        const to = data.filter(y => y.type == relationship.toType && y[relationship.toField] == x[relationship.fromField] && !fromKeys.includes(y[y.primaryKey]));
        to.forEach(y => {
          const key = x[x.primaryKey];
          if (!links.has(key)) {
            links.set(key, []);
          }
          links.get(key).push(y[y.primaryKey]);
          fromKeys.push(key);
        });
      });
    }

    // render all the links
    links.forEach((value, key) => {
      if (value.length == 0)
        return;
      graph += `  ${key} --> ${value.join(' & ')};\n`;
    });

    if (this.currentRecordId != null) {
      graph += `  style ${this.currentRecordId} stroke:#333,stroke-width:4px;\n`;
    }

    // this.graph = graph;
    mermaid.render('graph', graph, (svgCode, bindFunctions) => {
      var element = document.getElementById('graph-svg');
      element.innerHTML = svgCode;
      bindFunctions(element);
    });
  }
}
