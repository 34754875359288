<ng-template #template>
  <div class="modal-header bg-primary p-0">
    <div class="col p-2">
      <h6 class="text-light m-0">{{ modalConfig.Title }}</h6>
    </div>
    <div class="col-auto p-0">
      <span class="align-middle">
        <button type="button" class="btn btn-danger btn-block" (click)="Close()">
          <fa-icon [icon]="['fas', 'times']"></fa-icon>
        </button>
      </span>
    </div>
  </div>
  <div class="modal-body">
    <div [innerHTML]="modalConfig.Message"></div>
    <div class="form-row pt-4">
      <div *ngIf="modalConfig.CloseButtonVisible" class="col-auto">
        <button type="button" class="btn btn-outline-secondary" (click)="Close()">
          {{ modalConfig.CloseButtonText}}
        </button>
      </div>
      <div class="col">
      </div>
      <div class="col-auto">
        <button type="button" class="btn {{ modalConfig.OkButtonCss }}" (click)="Confirm()">
          {{ modalConfig.OkButtonText }}
        </button>
      </div>
    </div>
  </div>
</ng-template>