<div class="card hover" [ngClass]="{'brokerage-tile-hover': group}" style="min-height: 124px hover"
  (click)="selected.emit(data);" (mouseenter)="hover = true" (mouseleave)="hover = false" style="cursor: pointer;">
  <div class="card-body">
    <div class="form-row">
      <div class="col">
        <h5 class="card-title mb-3">
          {{ data.Name }}
        </h5>
      </div>
      <div class="col-auto pr-0">
        <span class="align-middle">
          <button *ngIf="showRemove && hover" type="button" class="btn btn-danger btn-block"
            (click)="remove.emit(data)">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
          </button>
        </span>
      </div>
    </div>
    <p class="card-text mb-0">
      {{ data.Board }}
    </p>
    <p class="card-text mb-0">
      {{ data.Address }}, {{ data.City }}, {{ data.ProvState }}
    </p>
    <p class="card-text mb-0">
      {{ type.get(data.ContractGroup) }}
    </p>
    <div *ngIf="data.PhoneNumber" class="form-row">
      <div class="col-auto" style="width: 30px">
        <fa-icon [icon]="['fas', 'phone']"></fa-icon>
      </div>
      <div class="col">
        {{data.PhoneNumber}}
      </div>
    </div>
    <div *ngIf="data.EmailAddress" class="form-row">
      <div class="col-auto" style="width: 30px">
        <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
      </div>
      <div class="col">
        {{data.EmailAddress}}
      </div>
    </div>
  </div>
</div>