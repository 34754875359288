<div *ngIf="_processing" class="form-row">
    <div class="col">
        Please wait until processing is complete. The next step will automatically show when finished.
    </div>
</div>
<div *ngIf="!_processing">
    <div class="alert alert-danger" role="alert" *ngIf="this._step == _steps.Preview && _signedInCityError">
        <span>Please ensure you enter the city you are signing from below.</span>
    </div>

    <div class="alert alert-danger" role="alert" *ngIf="this._step == _steps.RedFlag && _questionError">
        <span>Please ensure you have read and acknowledged the clauses below.</span>
    </div>

    <div [ngSwitch]="_step" *ngIf="_signatureConfig !== null">
        <div *ngSwitchCase="_steps.RedFlag">
            <div class="form-row form-group">
                <div class="col">
                    I verify I have read and understand the following clauses:
                </div>
            </div>
            <div class="form-row form-group" *ngFor="let question of _signatureConfig.Questions">
                <div class="col">
                    <span [innerHTML]="question"></span>
                </div>
                <div class="col-auto">
                    <ui-switch (change)="QuestionToggled($event)"></ui-switch>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="_steps.Initial">
            Please draw your initial in the box below.
            <!-- By initialing here I verify that I am <b>{{ signFor }}</b>, have read and understood each page of this contract,
        and agree to have my initials placed on each page of this contract. <b>Write your initials in the box below, filling as much of the box as possible.</b> -->
        </div>
        <div *ngSwitchCase="_steps.Signature">
            Please draw your signature in the box below.
            <!-- By signing I verify that I am <b>{{ signFor }}</b>, am the correct recipient of this contract, and agree to all
        terms and conditions relating to this contract. <b>Write your signature in the box below, filling as much of the box as possible.</b> -->
        </div>
        <div *ngSwitchCase="_steps.Preview">
            Please indicate the city you are signing from:
        </div>
    </div>

    <!-- SIGNATURE PAD -->
    <div class="form-row form-group mb-1"
        [ngStyle]="{'border': _step === _steps.Initial || _step === _steps.Signature ? '1px solid' : ''}" #row>
        <signature-pad [options]="_options"></signature-pad>
    </div>

    <div *ngIf="_step === _steps.Preview">
        <div class="form-row">
            <div class="form-group col-12">
                <input type="text" [(ngModel)]="_signedInCity" class="form-control" autocomplete="off" maxlength="100">
            </div>
            <div class="col-12">
                <span>Confirm your signature and initials below or click <i>Draw Signature</i> to draw your signature
                    and initials.</span>
            </div>
            <div class="col-auto">
                <img [src]="_cleanedSignaturePreview" class="img-fluid" style="max-width: 180px;">
            </div>
            <div>
                <img [src]="_cleanedInitialPreview" class="img-fluid" style="max-width: 74px;">
            </div>
            <div class="col-12 py-2">
                <button class="btn btn-block btn-primary" (click)="DrawSigs_OnClick();">
                    Draw Signature
                </button>
            </div>
        </div>
    </div>
    <div class="form-row" *ngIf="_step === _steps.Preview">
        <div class="small col-12">
            <span class="text-muted small-text">By clicking <b>Adopt & Sign</b> I verify that I am <b>{{signFor}}</b>,
                have read and understood each page of this contract, and agree to have my initials placed on each page
                of this contract.</span>
        </div>
    </div>
</div>