import { FileService } from './../../services/file.service';
import { AppLayoutComponent } from './../../layout/app-layout/app-layout.component';
import { FileRef } from './../../forms/classes';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ContractType } from '../../classes/contract';

@Component({
  selector: 'app-copy-file-modal',
  templateUrl: './copy-file-modal.component.html',
  styles: [
  ]
})
export class CopyFileModalComponent implements OnInit {
  @ViewChild('template', { static: true }) template: any;
  @Input() set fileRef(value: FileRef) {
    if (value != null) {
      this.openModal(this.template);
      this._selectedFileRef = value;
    }
  }
  @Output() cancel = new EventEmitter<void>();
  modalRef: BsModalRef;
  config: ModalOptions;
  selectedContract = null;
  private = true;
  _ct = ContractType;
  _selectedFileRef: FileRef = null;

  constructor(private modalService: BsModalService, private app: AppLayoutComponent, private svc: FileService) { }

  ngOnInit() {
    this.config = {
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-dialog-centered'
    };

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  confirm(): void {
    // this.selected.emit({ selected: this.selectedContract, private: this.private });
    this.app.ShowSpinner();
    this.svc
      .CopyFileToContract(
        this.selectedContract.ContractId,
        this._selectedFileRef.FileRefId,
        this.private
      )
      .subscribe(
        (response) => {
          this.HandleRouting();
        },
        (error) => this.app.ShowErrorMessage(error),
        () => {
          this._selectedFileRef = null;
          this.app.HideSpinner();
          this.modalRef.hide();
        }
      );
  }

  close(): void {
    this.cancel.emit();
    this.modalRef.hide();
  }

  decline(): void {
    this.cancel.emit();
    this.modalRef.hide();
  }

  private HandleRouting() {
    switch (this.selectedContract.Type) {
      case ContractType.Listing:
        this.app.router.navigate(['sellers', this.selectedContract.ContractId]);
        break;
      case ContractType.Buyer:
        this.app.router.navigate(['buyers', this.selectedContract.ContractId]);
        break;
      case ContractType.Offer:
        this.app.router.navigate(['offers', this.selectedContract.ContractId]);
        break;
      default:
        break;
    }
  }
}
