import { Brokerage } from './../../classes/classes';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-brokerage-tile',
  templateUrl: './brokerage-tile.component.html',
  styles: [`
    .brokerage-tile-hover {
      transition: all 0.2s;
      border: none;
      cursor: pointer;
      box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
      min-height: 200px;
    }

    .brokerage-tile-hover:hover {
      margin-top: -.25rem;
      margin-bottom: .25rem;
      box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    }
  `
  ]
})
export class BrokerageTileComponent implements OnInit {
  @Input() data: Brokerage;
  @Input() showRemove: boolean = false;
  @Input() group: boolean = true;
  @Output() selected = new EventEmitter<Brokerage>();
  @Output() remove = new EventEmitter<Brokerage>();


  type = new Map<string, string>([
    ['CA_AB_DA', 'Designated Agency'],
    ['CA_AB_CL', 'Common Law'],
  ]);

  hover: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
