// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  Production: true,
  AddrLookupUrl: 'https://expressentry.melissadata.net/web/GlobalExpressAddress?id=WxNQ7Uxs-cpVLf9ylxRRhB**&country=Canada&maxrecords=10',
  IPLookupUrl: 'https://api.ipify.org?format=json',
  SiteKey: '6LctF3kUAAAAAImde7EtFceU_zppDs_IMvH3xPFT',
  IntercomAppID: 'k5muvszh',
  MaxFileSizeInMB: 25,
  RunIntercom: true,
  PTKey: 'Real Deal Transaction Management Inc (rdtminc.com):OEM:DealSimple - Signature Module Only::B+:AMS(20230207):21A562BD04E7260AF360B13AC982537860613FA5CB008D6BDB2425849D34CBAE42B231F5C7'
};
