import { RoleType, RoleUtils } from './../../services/auth.service';
import { AppLayoutComponent } from './../app-layout/app-layout.component';
import { CalView } from './../../calendar/classes';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

class TabLinks {
  Route: string;
  Label: string;
  State?: string;
}

@Component({
  selector: 'app-nav-top',
  templateUrl: './app-nav-top.component.html',
  styleUrls: ['./app-nav-top.css']
})

export class AppNavTopComponent {

  trxShow: RegExp = /^\/(buyers|sellers|offers)/;
  trxLinks: TabLinks[] = [
    { Route: '/buyers', Label: 'Buyers' },
    { Route: '/sellers', Label: 'Sellers' },
    { Route: '/offers', Label: 'Offers' }
  ];
  listingShow: RegExp = /^\/(sellers)/;
  frmShow: RegExp = /^\/(realtorforms|myforms)/;
  frmLinks: TabLinks[] = RoleUtils.HasRole(this.app.cu.User?.UserType, RoleType.Agent) ?
    [
      { Route: '/realtorforms', Label: 'Realtor® Forms' },
      { Route: '/myforms', Label: 'My Forms' }
    ] :
    [{ Route: '/realtorforms', Label: 'Realtor® Forms' }];
  sigShow: RegExp = /^\/(desk)/;
  sigLinks: TabLinks[] = [
    { Route: '/desk/signatures', Label: 'Signatures' }
  ];
  adminShow: RegExp = /^\/(admin)/;
  adminLinks: TabLinks[] = [
    { Route: 'admin', Label: 'Home' },
    { Route: 'admin/brokerages', Label: 'Brokerages' },
    { Route: 'admin/upload', Label: 'File Upload' }
  ];
  _cv = CalView;
  calShow: RegExp = /^\/(calendar)/;
  calLinks: TabLinks[] = [
    { Route: 'calendar', Label: 'Day', State: 'Day' },
    { Route: 'calendar', Label: 'Week', State: 'Week' },
    { Route: 'calendar', Label: 'Month', State: 'Month' },
  ];

  contactShow: RegExp = /^\/(contacts)/;
  contactLinks: TabLinks[] = [
    { Route: '/contacts', Label: 'Contacts' }
  ];

  IsListingAgent(): boolean {
    return RoleUtils.HasRole(this.app.cu.User?.UserType, RoleType.ListingAgent);
  }

  CalLink_IsActive(link: TabLinks): boolean {
    return link.State === history.state.view || link.State.toLowerCase() === history.state.view;
  }
  constructor(public router: Router, public app: AppLayoutComponent) { }
}
