import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pair'
})
export class PairPipe implements PipeTransform {
  transform(items: any[]): [any, any][] {
    if (!items) {
      return [];
    }
    let pairs: [any, any][] = [];
    for (var i = 0; i < items?.length; i += 2) {
      pairs.push([items[i], items[i + 1] ? items[i + 1] : null]);
    }
    return pairs;
  }
}