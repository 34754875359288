<div class="row justify-content-md-center">
    <div class="col-lg-6 col-md-8 col-12">
        <form [formGroup]="frm" novalidate>

            <!-- FIRST STEP ENTER EMAIL -->
            <div *ngIf="_step.value === _steps.EmailStep || _step.value === _steps.NoUserStep">
                <div class="alert alert-danger" role="alert" *ngIf="_showErrors && !_email.valid">
                    Please provide a valid <b>Email Address</b>.
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        What is your email address?
                        <input type="email" class="form-control" formControlName="Email" maxlength="50"
                            autocomplete="new-password">
                    </div>
                </div>
                <div *ngIf="!_captcha.valid" class="form-row">
                    <div class="form-group col">
                        <re-captcha formControlName="Captcha" siteKey="{{_siteKey}}"></re-captcha>
                    </div>
                </div>
            </div>

            <!-- IF EMAIL EXISTS AND PASSWORD IS NULL BEGIN RECOVERY-->
            <div *ngIf="_step.value === _steps.MobileNumberStep">
                <div class="alert alert-danger" role="alert" *ngIf="_showErrors && !_mobile.valid">
                    The last <b>4 digits</b> of your phone number is required.
                </div>
                <div class="form-row">
                    <div class="form-group col-12">
                        We will send a temporary password to {{ frm.get('ProtectedMobilePhone').value }}. To verify
                        that this is your phone number, enter the last 4 digits including {{
                        (frm.get('LastTwoDigits').value) }}.
                    </div>
                    <div class="form-group col-12">
                        <input id="mobileConfirm" type="text" autocomplete="new-password" class="form-control"
                            formControlName="MobilePhone" minlength="4" maxlength="4"
                            placeholder="Last 4 digits of phone number">
                    </div>
                </div>
            </div>

            <!-- IF EMAIL EXISTS AND PASSWORD IS NULL BEGIN RECOVERY-->
            <div *ngIf="_step.value === _steps.PasscodeStep">
                <div class="alert alert-danger" role="alert" *ngIf="_showErrors && !_passcode.valid">
                    Code must be <b>6 digits</b> long.
                </div>
                <div class="form-row">
                    <div *ngIf="frm.get('IsMobileVerification').value" class="form-group col-12">
                        If {{ _mobile.value }} matches the last 4 digits of the phone number on your account, we'll
                        send you a code.
                    </div>
                    <div *ngIf="!frm.get('IsMobileVerification').value" class="form-group col-12">
                        A code has been sent to {{ _email.value }}.
                    </div>
                    <div class="form-group col-12">
                        Code *
                        <input autocomplete="new-password" class="form-control" formControlName="Passcode" minlength="6"
                            maxlength="6">
                    </div>
                </div>
            </div>

            <!-- IF PASSCODE IS CORRECT CHANGE PASSWORD-->
            <div *ngIf="_step.value === _steps.PasswordStep">
                <div class="alert alert-danger" role="alert"
                    *ngIf="_showErrors && (!_password.valid || _password.value !== _confirmPassword.value)">
                    <div *ngIf="!_password.valid">Your password must:
                        <ul>
                            <li>be at least 8 characters long</li>
                            <li>have 1 uppercase & 1 lowercase character</li>
                            <li>have 1 number</li>
                        </ul>
                    </div>
                    <div *ngIf="_password.value !== _confirmPassword.value">Passwords do not match</div>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        New Password *
                        <app-password-form-input [ParentForm]="frm" [GivenFormControlName]="'Password'"
                            [GivenId]="'Password'"></app-password-form-input>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        Confirm New Password *
                        <app-password-form-input [ParentForm]="frm" [GivenFormControlName]="'ConfirmPassword'"
                            [GivenId]="'ConfirmPassword'"></app-password-form-input>
                    </div>
                </div>
            </div>

            <!-- NEXT BUTTON -->
            <div *ngIf="_captcha.valid" class="form-row">
                <div class="col">
                </div>
                <div class="col-auto">
                    <button type="button" class="btn btn-primary btn-block" (click)="validate()">Next
                        <fa-icon [icon]="['fal', 'caret-right']"></fa-icon>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>