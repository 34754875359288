<div *ngIf="selectedBrokerage">
  <app-brokerage-tile [data]="selectedBrokerage" [group]="false">
  </app-brokerage-tile>
  <div class="form-group mt-3">
    <div class="alert alert-secondary" role="alert">
      <div class="form-row">
        <div class="col">
          CHANGE BROKERAGE TYPE
        </div>
      </div>
    </div>
    <div class="form-group form-row">
      <div class="col-auto">
        Brokerage Type:
      </div>
      <div class="col-auto">
        <select class="form-control" (change)="type_OnChange()" [(ngModel)]="type">
          <option *ngFor="let type of brokerageType" value="{{type[1]}}">
            {{type[0]}}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div *ngIf="showSave" class="form-group">
    <div class="form-row">
      <div class="col">
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-primary" (click)="saveBrokerage()">Save
        </button>
      </div>
    </div>
  </div>
  <div class="form-group mt-3">
    <div class="alert alert-secondary" role="alert">
      <div class="form-row">
        <div class="col">
          MANAGE BROKERAGE RELATIONS
        </div>
      </div>
    </div>
    <app-manage-brokerage-relations [currentBrokerageId]="brokerageId" [brokerages]="brokerages"
      (updateBrokerage)="saveBrokerage($event)">
    </app-manage-brokerage-relations>
  </div>
  <div class="form-group mt-3">
    <div class="alert alert-secondary" role="alert">
      <div class="form-row">
        <div class="col">
          BROKERAGE GRAPH
        </div>
      </div>
    </div>
    <app-graph [currentRecordId]="brokerageId" [config]="GetGraphConfig()">
    </app-graph>
  </div>

</div>