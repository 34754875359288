<div class="form-group" *ngIf="files.length != 0">
  <div class="form-row col-12">
    <div
      [ngClass]="{'card col-12 contract hover': file.Filename != _statusIndicatorName, 'status-indicator': file.Filename == _statusIndicatorName}"
      *ngFor="let file of files | paginate: { id: section | as : string, itemsPerPage: 5, currentPage: _pg }; let i = index">
      <div class="card-body p-2" *ngIf="file.Filename != _statusIndicatorName">
        <div class="form-row align-items-center">
          <div class="col" (click)="OpenFile(file); $event.stopPropagation()">
            <span class="card-title">
              <input *ngIf="deal.GetMode(file) === _mode.Rename" type="text" class="form-control"
                [formControl]="GetFilenameFormControl(file)" autocomplete="new-password" [hidden]="false">
              <button *ngIf="deal.GetMode(file) !== _mode.Rename" type="button" class="btn btn-link p-0">
                {{GetFileName(file)}}
              </button>
            </span>
          </div>
          <div id="divStatus"
            *ngIf="section == _dfs.Attachments && deal.df.contract.ContractType === _ct.Offer && deal.df.app.cu.User.UserId === file.UserId && file.FileRefType != _fr.FinalFINTRAC"
            class="col-auto">
            <div ngbDropdown class="d-inline-block" style="width: inherit">
              <button ngbDropdownToggle class="btn btn-dropdown dropdown-toggle"
                (click)="deal.Toggle_Permissions(file)">
                <fa-icon *ngIf="file.IsPrivate === true" [icon]="['far', 'lock']"></fa-icon>
                <fa-icon *ngIf="file.IsPrivate === false" [icon]="['far', 'unlock']"></fa-icon>
              </button>
            </div>
          </div>
          <div [ngSwitch]="deal.GetMode(file)">
            <div *ngSwitchCase="_mode.Rename" class="col-auto">
              <button type="button" class="btn btn-primary btn-xs"
                (click)="deal.Rename_OnAccept(file, GetFilename(file)); RemoveFilename(file)">
                <fa-icon [icon]="['fal', 'check']"></fa-icon>
              </button>
              <button type="button" class="btn btn-secondary btn-xs"
                (click)="deal.Rename_OnCancel(file); RemoveFilename(file)">
                <fa-icon [icon]="['fal', 'ban']"></fa-icon>
              </button>
            </div>
            <div *ngSwitchCase="_mode.Delete" class="col-auto">
              <button type="button" class="btn btn-danger btn-xs" (click)="deal.Delete_OnAccept(file)">
                <fa-icon [icon]="['fal', 'trash-alt']"></fa-icon>
              </button>
              <button type="button" class="btn btn-secondary btn-xs" (click)="deal.SetMode(file, _mode.Ready)">
                <fa-icon [icon]="['fal', 'ban']"></fa-icon>
              </button>
            </div>
            <div *ngSwitchDefault class="col-auto px-0">
              <div ngbDropdown placement="bottom-right" class="d-inline-block">
                <button *ngIf="(section | getActions : file : deal).length != 0" ngbDropdownToggle
                  class="btn btn-dropdown dropdown-toggle dropdownButton">
                  <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdown">
                  <button class="dropdown-item pointerCursor" *ngFor="let action of section | getActions : file : deal"
                    (click)="action[1](file)">
                    {{action[0]}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center" *ngIf="file.Filename == _statusIndicatorName">
        <div></div>
        <p class="badge badge-primary">
          NEW OFFER STARTED
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="files && files.length > 5" class="text-center">
    <pagination-controls [id]="section | as : string" (pageChange)="_pg = $event"></pagination-controls>
  </div>
</div>
<div class="form-row pl-4" *ngIf="files.length == 0">
  <i>There are no {{_dfs[section]}} attached to this contract</i>
</div>