import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { ngfModule } from 'angular-file';
import { NgxPaginationModule } from 'ngx-pagination';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AuthGuardAdmin } from '../guards/auth.guard.admin';
import { PipesModule } from '../pipes/pipes.module';
import { SearchModule } from '../search/search.module';
import { PageLayoutComponent } from '../shared/page-layout/page-layout.component';
import { SharedModule } from '../shared/shared.module';
import { AdminComponent } from './admin.component';
import { SearchUserComponent } from './search-user/search-user.component';
import { AdminService } from './service/admin.service';
import { FileAttachComponent } from './upload-data-file/file-attach/file-attach.component';
import { UserTileComponent } from './user-tile/user-tile.component';
import { UserSelectedComponent } from './user-selected/user-selected.component';
import { UserBillingModule } from '../user-billing/user-billing.module';
import { AddPaymentMethodComponent } from '../user-billing/add-payment-method/add-payment-method.component';
import { EditPaymentMethodInfoComponent } from '../user-billing/edit-payment-method-info/edit-payment-method-info.component';
import { ManageSubscriptionComponent } from '../user-billing/manage-subscription/manage-subscription.component';
import { ExtendFreeTrialComponent } from './extend-free-trial/extend-free-trial.component';
import { SearchBrokerageComponent } from './search-brokerage/search-brokerage.component';
import { BrokerageTileComponent } from './brokerage-tile/brokerage-tile.component';
import { BrokerageSelectedComponent } from './brokerage-selected/brokerage-selected.component';
import { GraphComponent } from './graph/graph.component';
import { ManageBrokerageRelationsComponent } from './manage-brokerage-relations/manage-brokerage-relations.component';
import { UploadDataFileComponent } from './upload-data-file/upload-data-file.component';

const routes: Routes = [
  {
    path: '',
    component: PageLayoutComponent,
    canActivate: [AuthGuardAdmin],
    children: [
      { path: '', component: AdminComponent, canActivate: [AuthGuardAdmin] },
      { path: 'user/:id', component: UserSelectedComponent, canActivate: [AuthGuardAdmin] },
      { path: 'user/:id/paymethod', component: AddPaymentMethodComponent, canActivate: [AuthGuardAdmin] },
      { path: 'user/:id/paymethod/:cid', component: EditPaymentMethodInfoComponent, canActivate: [AuthGuardAdmin] },
      { path: 'user/:id/subscription', component: ManageSubscriptionComponent, canActivate: [AuthGuardAdmin] },
      { path: 'brokerages', component: SearchBrokerageComponent, canActivate: [AuthGuardAdmin] },
      { path: 'brokerages/:id', component: BrokerageSelectedComponent, canActivate: [AuthGuardAdmin] },
      { path: 'upload', component: UploadDataFileComponent, canActivate: [AuthGuardAdmin] },
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    FontAwesomeModule,
    NgbDropdownModule,
    SharedModule,
    SearchModule,
    NgbTypeaheadModule,
    ngfModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    FormsModule,
    UiSwitchModule,
    UserBillingModule,
  ],
  declarations: [
    AdminComponent,
    FileAttachComponent,
    SearchUserComponent,
    UserTileComponent,
    UserSelectedComponent,
    ExtendFreeTrialComponent,
    SearchBrokerageComponent,
    BrokerageTileComponent,
    BrokerageSelectedComponent,
    GraphComponent,
    ManageBrokerageRelationsComponent,
    UploadDataFileComponent
  ],
  exports: [AdminComponent],
  providers: [AdminService]
})
export class AdminModule { }
