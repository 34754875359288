<div class="card" [ngClass]="{'hover': !largeTile}" (click)="selected.emit(contract); $event.stopPropagation();">
  <div class="card-body">
    <div class="form-row">
      <div class="col">
        <h5 class="card-title mb-0">
          <div *ngIf="!contract.Prop.AddrLine1">
            No Address
          </div>
          <div *ngIf="contract.Prop.AddrLine1">
            {{ contract.Prop.AddrLine1 }}, {{contract.Prop.AddrCity}}
          </div>
        </h5>
      </div>
      <div *ngIf="df && df.GetActions().length > 0" class="col-auto">
        <div class="d-none d-sm-block pr-0 position-absolute" style="left: -78px">
          <div ngbDropdown placement="bottom-right" class="d-inline-block">
            <button class="btn btn-primary dropdown-toggle" ngbDropdownToggle>Actions</button>
            <div ngbDropdownMenu>
              <button *ngFor="let action of df.GetActions()" ngbDropdownItem (click)="click.emit(action)">{{
                action.Name ? action.Name : action.ActionType.Name }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-auto d-sm-none pr-0">
          <div ngbDropdown placement="bottom-right" class="d-inline-block" style="width: inherit">
            <button class="btn btn-dropdown dropdown-toggle dropdownButton" id="dropdown" ngbDropdownToggle>
              <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdown">
              <button *ngFor="let action of df.GetActions()" class="dropdown-item pointerCursor"
                (click)="click.emit(action)">{{ action.Name ? action.Name : action.ActionType.Name }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="card-text mb-0">
      <b id="color" [ngClass]="cfu.getContractSignClass(contract.Status)">
        {{ GetStatus() }}
      </b>
      |
      {{ contract.Prop.Price ? '$' + (contract.Prop.Price | newDecimal:0) : 'No Price' }}
      {{ contract.Prop.ListingPrice ? '($' + (contract.Prop.ListingPrice | newDecimal:0) + ' Listing)': '' }}
    </p>

    <p *ngIf="formUtils.ConcatOwnerNames(contract.ContractOwners, true) !== 'No Seller'" class="card-text mb-0">
      <b>Seller: </b>
      <span>
        {{ formUtils.ConcatOwnerNames(contract.ContractOwners, true) }}
        <b>{{ RepresentedBy(true) }}</b>
        {{ WaitingForSeller(true) }}
      </span>
    </p>
    <p *ngIf="formUtils.ConcatOwnerNames(contract.ContractOwners, false) !== 'No Buyer'" class="card-text mb-0">
      <b>Buyer: </b>
      <span>
        {{ formUtils.ConcatOwnerNames(contract.ContractOwners, false) }}
        <b>{{ RepresentedBy(false) }}</b>
        {{ WaitingForSeller(false) }}
      </span>
    </p>
    <p *ngIf="ShowOfferExpires()" class="card-text mb-0">
      <b>Offer Expires: </b>
      <span>
        {{ contract.Prop.OfferExpires | newDate:'MMM d' }} at {{ contract.Prop.OfferExpires | newDate:'h:mm a' }}
      </span>
    </p>
    <p *ngIf="(contract.Status === status.Accepted || contract.Status === status.Firm || contract.Status === status.Closed) && contract.Prop.CompletionDate"
      class="card-text mb-0">
      <b>Possession Date: </b>
      <span>
        {{ contract.Prop.CompletionDate | newDate:'MMM d, yyyy' }}
      </span>
    </p>
    <div *ngIf="_hasCondition" class="form-row card-text mb-0">
      <div class="col-12">
        <b>Conditions: </b>
      </div>
      <div *ngFor="let condition of GetConditions()" class="col-lg-4 col-md-6 col-12">
        <i [ngClass]="condition.Waived ? 'fal fa-check-square' : 'fal fa-square'"></i>
        {{ condition.Name }} ({{ condition.DueDate | newDate:'MMM d' }})
      </div>
    </div>
    <!-- THIS IS THE NICER LOOKING WAY... KEEP FOR NOW
    <div *ngIf="_hasCondition" class="form-row card-text mb-0">
      <div class="col-12">
        <span *ngIf="_conditionsOpen > 0" class="badge badge-pill badge-primary mr-1">Open Conditions ({{ _conditionsOpen }})</span>
        <span *ngIf="_conditionsWaived > 0" class="badge badge-pill badge-success">Waived ({{ _conditionsWaived }})</span>
      </div>
    </div> 
    -->
    
  </div>
</div>