<div class="alert alert-danger" role="alert" *ngIf="fu.FormGroupHasError(fgForm)">
  <li *ngIf="fu.ShowControlError(_fileData)">
    You need to <b>attach a file</b>.
  </li>
  <li *ngIf="fu.ShowControlError(_country)">
    You need to <b>choose a country</b>.
  </li>
  <li *ngIf="fu.ShowControlError(_prov)">
    You need to <b>choose a province</b>.
  </li>
</div>
<div [formGroup]="fgForm">

  <div id="divStep1">
    <div class="alert alert-secondary" role="alert">
      <div class="form-row">
        <div class="col">
          1. ATTACH MEMBER LIST SPREADSHEET
        </div>
      </div>
    </div>
    <div class="form-row">
      <div *ngIf="!_fileData.value" class="form-group col">
        <app-file-attach [fgForm]="fgForm"></app-file-attach>
      </div>
      <div *ngIf="_fileData.value" class="form-group col">
        Thank you for attaching CSV file. Please fill out the rest of the form and submit.
      </div>
    </div>
  </div>

  <div id="divStep2">
    <div class="alert alert-secondary" role="alert">
      <div class="form-row">
        <div class="col">
          2. SELECT THE COUNTRY, PROVINCE, AND BOARD OF THE DATA FILE YOU ARE UPLOADING
        </div>
      </div>
    </div>

    <!-- TODO: REMOVE FILTERS ON DROPDOWNS TO ALLOW FOR OTHER COUNTRIES/PROVS -->
    <div [formGroup]="fgForm" class="form-row">
      <div class="form-group col-md-6 col-12">
        Country*
        <select name="selCountry" class="form-control" formControlName="Country" autocomplete="new-password">
          <option value=""></option>
          <option *ngFor="let country of data.Countries | filter: 'Canada'" value="{{country.Country}}">
            {{ country.Country }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-6 col-12">
        Province*
        <select name="selProv" class="form-control" formControlName="Province" autocomplete="new-password">
          <option value=""></option>
          <option *ngFor="let prov of data.Provinces | filter: 'Alberta'" value="{{prov.Abbrev}}">
            {{ prov.Province }}
          </option>
          <!-- <option *ngFor="let prov of data.Provinces | filter: fgForm.get('Country').value" value="{{prov.Abbrev}}">
            {{ prov.Province }}
          </option> -->
        </select>
      </div>
      <div class="form-group col-md-6 col-12">
        Board (if applicable)
        <input class="form-control" formControlName="Board" autocomplete="new-password" type="text">
      </div>
      <div class="form-group col-md-6 col-12">
        Upload Type*
        <select name="selCountry" class="form-control" formControlName="UploadType" autocomplete="new-password">
          <option value=""></option>
          <option *ngFor="let type of _uploadTypes" value="{{type}}">
            {{ _dfut[type] }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div id="divSubmit" class="form-row">
    <div class="form-group col-md-6 col-12">
      <button type="button" class="btn btn-secondary btn-block" (click)="Reset()">
        Reset
      </button>
    </div>
    <div class="form-group col-md-6 col-12">
      <button type="button" class="btn btn-primary btn-block" (click)="Submit_Click()">
        Submit
      </button>
    </div>
  </div>
</div>