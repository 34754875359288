<div class="form-row">
    <div class="form-group col-12">
        <input [(ngModel)]="search" name="search" type="text" class="form-control"
            placeholder="Search for a registered user..." autocomplete="off"
            (keyup)="searchText.next($any($event).target.value)" maxlength="30" />
    </div>
</div>

<div class="text-center" *ngIf="users && users.length > 5">
    <pagination-controls (pageChange)="pg = $event"></pagination-controls>
</div>

<div class="form-row">
    <div class="form-group col-12 mb-0" *ngFor="let user of users |
    paginate: { itemsPerPage: 5, currentPage: pg }; let i = index">
        <app-user-tile [data]="user" (selected)="user_Selected($event)">
        </app-user-tile>
    </div>
</div>

<div class="form-group" *ngIf="count === 0">
    <div class="alert alert-warning" role="alert">
        <div class="col">
            The user you were looking for was not found.
        </div>
    </div>
</div>