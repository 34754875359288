import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from '../pipes/pipes.module';
import { AgentTileComponent } from './agent-tile/agent-tile.component';
import { BrokerageTileComponent } from './brokerage-tile/brokerage-tile.component';
import { BuyerTileComponent, BuyerTypePipe } from './buyer-tile/buyer-tile.component';
import { EnvelopeTileComponent } from './envelope-tile/envelope-tile.component';
import { ListingTileComponent, SellerTypePipe } from './listing-tile/listing-tile.component';
import { NoContractTileComponent } from './no-contract-tile/no-contract-tile.component';
import { NoEnvelopeTileComponent } from './no-envelope-tile/no-envelope-tile.component';
import { OfferTileComponent } from './offer-tile/offer-tile.component';
import { PropertyPoolTileComponent } from './property-pool-tile/property-pool-tile.component';
import { ContactTileComponent } from './contact-tile/contact-tile.component';
import { NewContactTileComponent } from './new-contact-tile/new-contact-tile.component';
import { NoContactTileComponent } from './no-contact-tile/no-contact-tile.component';
import { ConveyancerTileComponent } from './conveyancer-tile/conveyancer-tile.component';
import { NewConveyancerTileComponent } from './new-conveyancer-tile/new-conveyancer-tile.component';
import { NoConveyancerTileComponent } from './no-conveyancer-tile/no-conveyancer-tile.component';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    FontAwesomeModule,
    NgbDropdownModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    AgentTileComponent,
    BrokerageTileComponent,
    BuyerTileComponent,
    EnvelopeTileComponent,
    ListingTileComponent,
    OfferTileComponent,
    PropertyPoolTileComponent,
    NoContractTileComponent,
    NoEnvelopeTileComponent,
    ContactTileComponent,
    NewContactTileComponent,
    NoContactTileComponent,
    BuyerTypePipe,
    SellerTypePipe,
    ConveyancerTileComponent,
    NewConveyancerTileComponent,
    NoConveyancerTileComponent
  ],
  exports: [
    AgentTileComponent,
    BrokerageTileComponent,
    BuyerTileComponent,
    EnvelopeTileComponent,
    ListingTileComponent,
    OfferTileComponent,
    PropertyPoolTileComponent,
    NoContractTileComponent,
    NoEnvelopeTileComponent,
    ContactTileComponent,
    NewContactTileComponent,
    NoContactTileComponent,
    ConveyancerTileComponent,
    NewConveyancerTileComponent,
    NoConveyancerTileComponent
  ]
})
export class TilesModule { }
