import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormGroup } from '@angular/forms';
import { User, YesNoPromptConfig } from '../classes/classes';
import { AppLayoutComponent } from '../layout/app-layout/app-layout.component';
import { AdminService } from './service/admin.service';
import ConfigUtils from '../utils/ConfigUtils';
import { PageLayoutComponent } from '../shared/page-layout/page-layout.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html'
})
export class AdminComponent implements OnInit {
  cu = ConfigUtils;

  constructor(public app: AppLayoutComponent, private asvc: AdminService, private fb: UntypedFormBuilder, private layout: PageLayoutComponent) {
    // this.layout.SetPageTitle('Admin');
  }

  ngOnInit() {
    if (!this.app.cu.User.EmailAddress.includes('@rdtminc.com')) {
      this.app.router.navigate(['/']);
      return;
    }
  }

  public user_Selected(user: User) {
    this.app.router.navigate([this.app.router.url, 'user', user.UserId]);

  }

  public ResetDemo() {

    this.app.ShowSpinner();
    this.asvc.ResetDemo()
      .subscribe(
        () => {
          this.app.HideSpinner();
          this.app.modal.ShowModal('Reset complete!');
        },
        error => this.app.modal.ShowError(error)
      );
  }
}
