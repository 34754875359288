import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbAlertModule, NgbButtonsModule, NgbDatepickerModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { SignaturePadModule } from 'angular2-signaturepad';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxEditorModule } from 'ngx-editor';
import { NgxPaginationModule } from 'ngx-pagination';
import { ShowHidePasswordModule, ShowHideService } from 'ngx-show-hide-password';
import { UiSwitchModule } from 'ngx-ui-switch';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { AddressLookupComponent } from './address-lookup/address-lookup.component';
import { DateTimePickerComponent } from './datetime-picker/datetime-picker.component';
import { DismissibleAlertsComponent } from './dismissible-alerts/dismissible-alerts.component';
import { DoubleEndsWizardComponent } from './double-ends-wizard/double-ends-wizard.component';
import { DynamicControlFieldComponent } from './dynamic-control-field/dynamic-control-field.component';
import { DynamicObjectSummaryComponent } from './dynamic-object-summary/dynamic-object-summary.component';
import { FilePickerComponent } from './file-picker/file-picker.component';
import { HintComponent } from './hint/hint.component';
import { ModalComponent } from './modal/modal.component';
import { PageLayoutModule } from './page-layout/page-layout.module';
import { PasswordFormInputComponent } from './password-form-input/password-form-input.component';
import { PostRepresentationModalComponent } from './post-representation-modal/post-representation-modal.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RichTextEditorComponent } from './rich-text-editor/rich-text-editor.component';
import { SignatureModalComponent } from './signature-modal/signature-modal.component';
import { SignatureFieldComponent } from './signature/signature.component';
import { StatusFilterComponent } from './status-filter/status-filter.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { YesNoPromptComponent } from './yes-no-prompt/yes-no-prompt.component';

@NgModule({
  imports: [
    BsDatepickerModule.forRoot(),
    CommonModule,
    CollapseModule.forRoot(),
    DirectivesModule,
    FontAwesomeModule,
    FormsModule,
    ModalModule.forRoot(),
    Ng2SearchPipeModule,
    NgbAlertModule,
    NgbButtonsModule,
    NgbDatepickerModule,
    NgbTypeaheadModule,
    NgxPaginationModule,
    PipesModule,
    PopoverModule.forRoot(),
    ReactiveFormsModule,
    RouterModule,
    ShowHidePasswordModule,
    SignaturePadModule,
    UiSwitchModule,
    NgxEditorModule,
    NgxDaterangepickerMd.forRoot()
  ],
  declarations: [
    AddressLookupComponent,
    DateTimePickerComponent,
    DismissibleAlertsComponent,
    DoubleEndsWizardComponent,
    FilePickerComponent,
    HintComponent,
    ModalComponent,
    PasswordFormInputComponent,
    PrivacyPolicyComponent,
    SignatureFieldComponent,
    TermsOfServiceComponent,
    TimePickerComponent,
    YesNoPromptComponent,
    StatusFilterComponent,
    DynamicControlFieldComponent,
    RichTextEditorComponent,
    DynamicObjectSummaryComponent,
    SignatureModalComponent,
    PostRepresentationModalComponent
  ],
  providers: [
    ShowHideService
  ],
  exports: [
    AddressLookupComponent,
    DateTimePickerComponent,
    DismissibleAlertsComponent,
    DoubleEndsWizardComponent,
    FilePickerComponent,
    HintComponent,
    ModalComponent,
    PageLayoutModule,
    PasswordFormInputComponent,
    ReactiveFormsModule,
    SignatureFieldComponent,
    TimePickerComponent,
    YesNoPromptComponent,
    StatusFilterComponent,
    DynamicControlFieldComponent,
    RichTextEditorComponent,
    DynamicObjectSummaryComponent,
    SignatureModalComponent,
    PostRepresentationModalComponent
  ]
})
export class SharedModule { }
