<div *ngIf="_showAddParent">
  <h5 class="mb-0">Select Parent</h5>
  <hr class="mt-0">
  <app-search-brokerage [navigate]="false" [getGroupless]="false" [pair]="true" [groupAnimations]="true"
    (selected)="selectParent($event)">
  </app-search-brokerage>
</div>

<div *ngIf="_showAddChild">
  <h5 class="mb-0">Add Children</h5>
  <hr class="mt-0">
  <app-search-brokerage [navigate]="false" [getGroupless]="false" [pair]="true" [groupAnimations]="true"
    (selected)="addAsChild($event)">
  </app-search-brokerage>
</div>

<div *ngIf="_hasChild">
  <h5 class="mb-0">Manage Children</h5>
  <hr class="mt-0">
  <div *ngFor="let pair of _children | pair; let i = index">
    <div class="form-row">
      <div [ngClass]="_smallScreen ? 'col-12' : 'col-6' ">
        <app-brokerage-tile [data]="pair[0]" [showRemove]="true" (remove)="removeChild($event)">
        </app-brokerage-tile>
      </div>
      <div [ngClass]="_smallScreen ? 'col-12' : 'col-6'" *ngIf="pair[1] != null && pair[1] != undefined">
        <app-brokerage-tile [data]="pair[1]" [showRemove]="true" (remove)="removeChild($event)">
        </app-brokerage-tile>
      </div>
    </div>
  </div>
</div>

<div *ngIf="_parent != null">
  <h5 class="mb-0">Manage Parent</h5>
  <hr class="mt-0">
  <div class="form-row">
    <div class="col-12">
      <app-brokerage-tile [data]="_parent" [showRemove]="true" (remove)="removeChild($event)">
      </app-brokerage-tile>
    </div>
  </div>
</div>