<ng-template #signatureModal>
  <div class="card">
    <div class="modal-header bg-primary p-0">
      <div class="col p-2">
        <h6 class="text-light m-0">{{ fullName }}{{ sigComponent._processing ? " - Please wait..." : "" }}</h6>
      </div>
      <div *ngIf="!sigComponent._processing" class="col-auto p-0">
        <span class="align-middle">
          <button type="button" class="btn btn-danger btn-block" (click)="Cancel()">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
          </button>
        </span>
      </div>
    </div>
    <div class="card-body">
      <app-signature #sigComponent [parentId]="parentId" [fileRefId]="fileRefId" [signerId]="signerId"
        [signFor]="fullName" (signed)="signature.emit($event)">
      </app-signature>
    </div>
    <!-- BUTTONS -->
    <div *ngIf="!sigComponent._processing">
      <div *ngIf="sigComponent.IsSignatureStep()" class="modal-footer">
        <div class="col-auto p-0">
          <button type="button" class="btn btn-secondary" (click)="Cancel();">
            Cancel
          </button>
        </div>
        <div class="col p-0">
          <button type="button" [disabled]="!sigComponent.ValidSignature()" class="btn btn-secondary"
            (click)="sigComponent.Clear();">
            Clear
          </button>
        </div>
        <div class="col-auto p-0">`
          <button type="button" [disabled]="!sigComponent.ValidSignature()" class="btn btn-primary"
            (click)="this.sigComponent.Next();">
            Next
          </button>
        </div>
      </div>
      <div *ngIf="!sigComponent.IsSignatureStep()" class="modal-footer px-0">
        <div class="col">
          <button type="button" class="btn btn-secondary" (click)="Cancel();">
            Cancel
          </button>
        </div>
        <div class="col-auto">
          <button type="button" class="btn btn-primary" (click)="sigComponent.Next();">
            {{ sigComponent._step === sigComponent._steps.RedFlag ? 'Next' : 'Adopt & Sign' }}
          </button>
          <!-- [disabled]="sigComponent._processing" -->
        </div>
      </div>
    </div>
  </div>
</ng-template>