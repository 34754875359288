import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../../classes/classes';

@Component({
  selector: 'app-user-tile',
  templateUrl: './user-tile.component.html'
})
export class UserTileComponent implements OnInit {

  // #region Inputs/Outputs
  @Input() clickable = true;
  @Input() data: User;
  @Output() selected: EventEmitter<User> = new EventEmitter<User>();
  // #endregion

  // #region Constructor
  constructor() { }
  // #endregion

  ngOnInit() {
  }

}
