import { AppLayoutComponent } from './../../layout/app-layout/app-layout.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AdminService } from './../service/admin.service';
import { Brokerage } from './../../classes/classes';
import { Subject } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
@Component({
  selector: 'app-search-brokerage',
  templateUrl: './search-brokerage.component.html',
  styles: [
  ]
})
export class SearchBrokerageComponent implements OnInit {

  @Input() navigate: boolean = true;
  @Input() getGroupless: boolean = true;
  @Input() groupAnimations: boolean = false;
  @Input() pair: boolean = false;
  @Output() selected = new EventEmitter<Brokerage>();


  searchText = new Subject<string>();
  search: string;
  brokerages: Brokerage[] = [];
  pg = 1;
  count = -1;
  _smallScreen: boolean = false;

  constructor(private svc: AdminService, private app: AppLayoutComponent) {
    this.InitSearchDelay();
  }
  ngOnInit() {
    if (this.getGroupless)
      this.Query('');

    this._smallScreen = this.app.smallScreen;
  }

  InitSearchDelay() {
    this.searchText.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(text => this.Search(text));
  }

  ResetSearch(searchText: string = ''): boolean {

    if (searchText.trim().length === 0) {
      this.brokerages = [];
      this.count = -1;
      this.search = null;

      return true;
    }

    return false;
  }

  Search(searchText: string) {

    this.pg = 1;

    // IF BLANK, DO NOT CONTINUE
    if (this.ResetSearch(searchText)) {
      return;
    }

    // SEARCH FOR USERS
    this.Query(searchText);
  }

  private Query(searchText: string) {
    this.svc.QueryBrokerages(searchText, this.getGroupless)
      .subscribe(
        response => {
          this.brokerages = response;
          this.count = this.brokerages.length;
        },
        error => {
          console.log(error);
        }
      );
  }

  public brokerage_Selected(brokerage: Brokerage) {
    this.selected.emit(brokerage);
    this.ResetSearch();
    if (this.navigate)
      this.app.router.navigate([this.app.router.url, brokerage.BrokerageId]);
  }

}
