import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import WebViewer, {
  Annotations,
  CoreControls,
  Tools,
  WebViewerInstance
} from '@pdftron/webviewer';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { environment } from '../../../environments/environment';
import { Signature, SignatureArtifacts, SignatureType } from '../../classes/signature';
import { Envelope, EnvelopeStatus } from '../../envelopes/classes';
import { FileRef } from '../../forms/classes';
import { AppLayoutComponent } from '../../layout/app-layout/app-layout.component';
import { FileService } from '../../services/file.service';
import { SignatureService } from '../../services/signature.service';
import { SignerService } from '../../services/signer.service';
import { Signer } from '../../utils/data/SignerUtils';
import GuidUtils from '../../utils/GuidUtils';

export enum AnnotFieldType {
  Initial = 100,
  Sign = 200,
}

@Component({
  selector: 'app-pdf-sign-viewer',
  templateUrl: './pdf-sign-viewer.component.html',
})
export class PDFSignViewerComponent implements OnInit {
  @ViewChild('viewer', { static: true }) divViewer: ElementRef;
  //#region INPUT/OUTPUT
  @Input() modal: BsModalRef = null;
  @Input() envelope: Envelope = null;

  @Output() afterLoadComplete = new EventEmitter();
  @Output() afterSignature = new EventEmitter<Envelope>();
  @Output() error = new EventEmitter<any>();
  //#endregion

  //#region GLOBAL VARS
  _fileRef: FileRef = null;
  _pdf: ArrayBuffer = null;
  _filename = 'File.pdf';
  _wvi: WebViewerInstance = null;
  _dropPoint = { x: 0, y: 0 };
  _type = AnnotFieldType;
  _status = EnvelopeStatus;
  _signerDisabledElements: string[] = [
    'thumbDelete',
    'thumbMultiDelete',
    'toggleNotesButton',
    'menuButton',
    'annotationCommentButton',
    'linkButton',
    'selectToolButton',
    'printButton',
    'downloadButton',
    'imageSignaturePanelButton',
  ];

  _agentDisabledElements: string[] = [
    'annotationCommentButton',
    'linkButton',
    'textSquigglyToolButton',
    'freeHandToolButton',
    'stickyToolButton',
    'notesPanel',
    'notesPanelButton',
    'toggleNotesButton',
  ];
  _currentFileRefAnnotations: Annotations.Annotation[] = [];
  _annotPosition = -1;
  _annotNext = false;
  _annotPrev = false;

  _signature: Signature = null;
  _postingSignature: Signature = null;
  _sigWidget: any;
  _showSigModal = false;
  _signatureArtifacts: SignatureArtifacts;
  _signatureBorderDimensions: { w: number, h: number; } = { w: 0, h: 0 };
  _initialBorderDimensions: { w: number, h: number; } = { w: 0, h: 0 };
  //#endregion

  constructor(
    public app: AppLayoutComponent,
    private svcFile: FileService,
    private svcSigner: SignerService,
    private svcSig: SignatureService,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    this.InitViewer();
    this.app.LoadCurrentUserIP();

    // SET SIGNERS SIGNATURE
    if (this.envelope.Signers.length === 1) {
      this._signature = this.envelope.Signers[0].Signature;
      // GET SIGNATURE ARTIFACTS
      this.GetSignatureArtifacts();
    }

    // OPEN THE FIRST FILE BY DEFAULT
    if (this.envelope.FileRefs.length > 0) {
      const file = this.envelope.FileRefs.find((x) => x.SortOrder === 0);
      if (file) {
        this.OpenFileRef(file.FileRefId);
      }
    }
  }

  public HandleSignature(sig: Signature) {
    if (sig) {
      // SET SIGNATURE HERE
      this._signature = sig;
      this.GetSignatureArtifacts(true);
    }
    this._showSigModal = !this._showSigModal;
  }
  //#region Open File functions
  public OpenFileRef(fileRefId: string = null) {
    const file = this.envelope.FileRefs.find((x) => x.FileRefId === fileRefId);
    if (file !== null) {
      this._fileRef = file;
      this._filename = `${file.Filename}${file.FileExt}`;
      if (!this.app.isSigner()) {
        this.svcFile.DownloadFile(file.FileRefId).subscribe((resp) => {
          this.OpenFile(resp);
          this.InitEvents();
        });
      } else {
        this.svcSigner.DownloadFile(file.FileRefId).subscribe((resp) => {
          this.OpenFile(resp);
          this.InitEvents();
        });
      }
    }
  }
  private GetSignatureArtifacts(modalClosing = false) {
    this.svcSig.getSignatureArtifacts(this.envelope.EnvelopeId)
      .subscribe(
        response => this._signatureArtifacts = response,
        error => console.log(error),
        () => {
          this.GetBorderSizes();
          if (modalClosing) {
            const { docViewer, Tools } = this._wvi;
            const signatureTool = docViewer.getTool(Tools.ToolNames.SIGNATURE) as Tools.SignatureCreateTool;
            signatureTool.trigger('locationSelected');
          }
        }
      );
  }
  private GetBorderSizes() {
    const sigImg = new Image();
    const initImg = new Image();
    sigImg.src = this._signatureArtifacts?.SignatureBorder;
    initImg.src = this._signatureArtifacts?.InitialBorder;
    sigImg.onload = () => {
      this._signatureBorderDimensions = { w: sigImg.width, h: sigImg.height };
    };
    initImg.onload = () => {
      this._initialBorderDimensions = { w: initImg.width, h: initImg.height };
    };
  }
  private OpenFile(data: ArrayBuffer = null) {
    this._pdf = data;
    if (!data) {
      return;
    }
    if (!this._wvi) {
      return;
    }

    this._wvi.loadDocument(
      new Blob([new Uint8Array(this._pdf)], { type: 'application/pdf' }),
      {
        documentId: GuidUtils.GenerateGuid(),
        filename: this._filename,
      }
    );

    this.afterLoadComplete.emit();
  }
  //#endregion
  //#region Initialize Functions
  private InitViewer() {
    const cca: Annotations.Annotation[] = [];
    this._wvi = null;
    WebViewer(
      {
        licenseKey: environment.PTKey,
        path: '../assets/lib',
        disabledElements: this.app.isSigner()
          ? this._signerDisabledElements
          : this._agentDisabledElements,
      },
      this.divViewer.nativeElement
    )
      .then((instance) => {
        const { Annotations, annotManager, iframeWindow, Tools, docViewer } = instance;
        this._wvi = instance;

        this.ManageUIView(annotManager);

        // SET INITIAL/SIGN HERE TEXT
        const createSignHereElement =
          Annotations.SignatureWidgetAnnotation.prototype.createSignHereElement;
        Annotations.SignatureWidgetAnnotation.prototype.createSignHereElement = function () {
          Tools.SignatureCreateTool.setTextHandler((_) => {
            return this.CustomData.type === `${AnnotFieldType.Initial}`
              ? 'Initial'
              : 'Sign';
          });
          const signHereElement = createSignHereElement.apply(this, arguments);
          signHereElement.style.background = '#00afef';

          return signHereElement;
        };

        // NEW SIGNATURE METHOD, SIGN ONCE, PLACE EVERYWHERE
        const signatureTool = docViewer.getTool(Tools.ToolNames.SIGNATURE) as Tools.SignatureCreateTool;
        signatureTool.on('locationSelected', (coord: Tools.PageCoordinate, sigWidget: Annotations.SignatureWidgetAnnotation) => {
          this.ProduceSignatures(signatureTool, sigWidget);
        });

        iframeWindow.document.body.addEventListener('dragover', (e) => {
          e.preventDefault();
        });
        iframeWindow.document.body.addEventListener('drop', (e) => {
          this.Drag_Drop(e);
        });

        this._wvi.setHeaderItems(() => {
          if (this._pdf) {
            this.OpenFile(this._pdf);
          }
        });
      })
      .finally(() => this.InitEvents());
  }

  private ProduceSignatures(signatureTool: Tools.SignatureCreateTool, sigWidget: Annotations.SignatureWidgetAnnotation) {
    const { Annotations, annotManager, CoreControls } = this._wvi;

    if (this._signature == null) {
      this.ngZone.run(() => {
        this._showSigModal = true;
        this.app.ShowSpinner();
        this._sigWidget = sigWidget;
      });
    } else {
      if (sigWidget == null) { sigWidget = this._sigWidget; } else { this._sigWidget = sigWidget; }
      const border = new Annotations.StampAnnotation();
      let borderScale = 1;
      if (+sigWidget.CustomData.type === SignatureType.Initial) {
        border.setImageData(this._signatureArtifacts.InitialBorder);
        borderScale = sigWidget.getHeight() / this._initialBorderDimensions.h;
        border.setWidth(this._initialBorderDimensions.w * borderScale);
        border.setHeight(this._initialBorderDimensions.h * borderScale);
        if (this._signatureArtifacts.TypedInitial) {
          signatureTool.setSignature(this._signatureArtifacts.TypedInitial);
        } else {
          signatureTool.setStyles({ StrokeColor: '#000000', StrokeThickness: '1px', Opacity: '100%' });
          signatureTool.setSignature(JSON.parse(this._signature.InitialData));
        }
      } else if (+sigWidget.CustomData.type === SignatureType.Signature) {
        border.setImageData(this._signatureArtifacts.SignatureBorder);
        borderScale = sigWidget.getHeight() / this._signatureBorderDimensions.h;
        border.setWidth(this._signatureBorderDimensions.w * borderScale);
        border.setHeight(this._signatureBorderDimensions.h * borderScale);
        if (this._signatureArtifacts.TypedSignature) {
          signatureTool.setSignature(this._signatureArtifacts.TypedSignature);
        } else {
          signatureTool.setStyles({ StrokeColor: '#000000', StrokeThickness: '1px', Opacity: '100%' });
          signatureTool.setSignature(JSON.parse(this._signature.SignatureData));
          signatureTool.annot.Height = border.Height;
          signatureTool.annot.Width = border.Width;
        }
      }

      border.setPageNumber(sigWidget.getPageNumber());
      border.setX(sigWidget.getX());
      border.setY(sigWidget.getY());
      this.SetPermissions(border);
      border.Author = annotManager.getCurrentUser();
      const ts = new Annotations.FreeTextAnnotation();
      ts.setPageNumber(sigWidget.getPageNumber());
      const zone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
      const timeStampLocal = `${new DatePipe('en-US').transform(signatureTool.annot.DateCreated, 'dd-MMMM-YYYY hh:mm aaa')} ${zone}`;
      ts.setContents(timeStampLocal);
      ts.X = (border.getX() + (border.getWidth() / 2) - 5);
      ts.Y = border.getY() + border.getHeight() - 6;
      ts.Width = border.getWidth();
      ts.Height = 8;
      ts.setPadding(new CoreControls.Math.Rect(0, 0, 0, 0));
      ts.Opacity = 100;
      ts.FontSize = '5px';
      ts.StrokeThickness = 0;
      ts.StrokeColor = new Annotations.Color(255, 255, 255, 0);
      ts.TextColor = new Annotations.Color(0, 0, 0, 1);
      this.SetPermissions(ts);
      ts.Author = annotManager.getCurrentUser();

      if (+sigWidget.CustomData.type === SignatureType.Initial) {
        this._sigWidget.setCustomData('associateLinks', [border.Id]);
        annotManager.addAnnotation(border);
      } else {
        this._sigWidget.setCustomData('associateLinks', [border.Id, ts.Id]);
        annotManager.addAnnotation([border, ts]);
      }

      const date = new Date(signatureTool.annot.DateCreated);
      date.setMinutes(date.getMinutes() - signatureTool.annot.DateCreated.getTimezoneOffset());

      this._postingSignature = new Signature({
        IPAddress: this.app.ipAddress,
        TimeStamp: signatureTool.annot.DateCreated,
        TimeStampLocal: date,
        SignedInCity: this._signature.SignedInCity
      });
      signatureTool.addSignature();
    }
    this._wvi.closeElements(['signatureModal']);
  }

  private ManageUIView(annotManager: CoreControls.AnnotationManager) {
    if (this.app.isSigner()) {
      annotManager.setCurrentUser(this.app.cu.SignerName);
      this.RestrictSignerAccess();
    } else {
      if (this.envelope.Status === EnvelopeStatus.AwaitingSignatures) {
        annotManager.setReadOnly(true);
      }
      annotManager.setCurrentUser(
        `${this.app.cu.User.FirstName} ${this.app.cu.User.LastName}`
      );
      this.DisableUnusedElements();
    }
  }

  private DisableUnusedElements(): void {
    this._wvi.disableElements(['toolbarGroup-Shapes']);
    this._wvi.disableElements(['toolbarGroup-Insert']);
    this._wvi.disableElements(['toolbarGroup-Edit']);
    this._wvi.disableElements(['squigglyToolGroupButton']);
    this._wvi.disableElements(['stickyToolGroupButton']);
    this._wvi.disableElements(['shapeToolGroupButton']);
    this._wvi.disableElements(['freeHandToolGroupButton']);
  }

  private RestrictSignerAccess() {
    this._wvi.disableElements(['toolbarGroup-Shapes']);
    this._wvi.disableElements(['toolbarGroup-Annotate']);
    this._wvi.disableElements(['toolbarGroup-Edit']);
    this._wvi.disableElements(['toolbarGroup-Insert']);
    this._wvi.disableElements(['ToolsOverlayContainer']);
    this._wvi.disableElements(['signatureModal']);
    this._wvi.disableElements(['rubberStampToolButton']);
    this._wvi.disableElements(['stampToolButton']);
    this._wvi.disableElements(['fileAttachmentToolButton']);
    this._wvi.disableElements(['annotationGroupButton']);
    this._wvi.disableElements(['calloutToolGroupButton']);
    this._wvi.disableElements(['eraserToolButton']);
    this._wvi.disableElements(['signatureToolButton']);
    this._wvi.disableElements(['textPopup']);
    this._wvi.disableElements(['contextMenuPopup']);
    this._wvi.openElements(['toolbarGroup-View']);
  }

  private InitEvents() {
    if (!this._wvi) {
      return;
    }
    const { docViewer } = this._wvi;

    docViewer.one('documentLoaded', () => {
      this._wvi.setFitMode('FitWidth');
      this.LoadAnnotations();
    });
  }
  //#endregion
  //#region Save Annotations
  private SaveAnnotations(
    annotations: Annotations.Annotation[],
    action: string
  ) {
    const { Annotations, annotManager } = this._wvi;
    annotations.forEach((annotation) => {
      if (
        (annotation instanceof Annotations.FreeHandAnnotation ||
          annotation instanceof Annotations.StampAnnotation) &&
        annotation.Subject === 'Signature'
      ) {
        const widget = this._currentFileRefAnnotations.find((x) => x.getX() === this._sigWidget.X && x.getY() === this._sigWidget.Y);
        if (!widget) {
          return;
        }

        widget.Hidden = true;
        annotation.CustomData = widget.CustomData;
        annotation.associateLink(this._sigWidget.CustomData.associateLinks);

        if (annotation instanceof Annotations.FreeHandAnnotation) {
          annotation.Height = (widget.Height * 0.8);
          if (annotation.CustomData.type === SignatureType.Initial) {
            annotation.X = (widget.X + (widget.Width * .1));
          } else {
            annotation.X = (widget.X + (widget.Width * .025));
          }
          annotation.Width = (widget.Width * 0.8);
          annotation.Y = (widget.Y + (widget.Height * .1));
        }

        this.SetPermissions(annotation);
        this.AddSignatureAnnotation(annotation);
      } else if (this.envelope.Status === EnvelopeStatus.InProgress) {
        switch (action) {
          case 'add':
            // CHECK IF COPIED AND GIVE NEW GUID
            if (
              annotation.getCustomData('id') &&
              this._currentFileRefAnnotations.findIndex(
                (x) => x.CustomData.id === annotation.CustomData.id
              ) > -1
            ) {
              const ids = annotation.getCustomData('id').split(';');
              annotation.setCustomData(
                'id',
                `${ids[0]};${GuidUtils.GenerateGuid()}`
              );

              // SET OPACITY OF SIG WIDGET
              this.SetWidgetStyle(annotation);
            }
            this._currentFileRefAnnotations.push(annotation);
            break;
          case 'modify':
            const modIndex = this._currentFileRefAnnotations.findIndex(
              (x) => x.CustomData === annotation.CustomData
            );
            if (modIndex > -1) {
              this._currentFileRefAnnotations[modIndex] = annotation;
            }
            break;
          case 'delete':
            this._currentFileRefAnnotations.splice(
              this._currentFileRefAnnotations.indexOf(annotation),
              1
            );
            break;
        }
        this.UpdateFileRefAnnotations();
      }
    });
  }
  //#endregion
  //#region Annotation Functions
  private UpdateFileRefAnnotations() {
    const { annotManager } = this._wvi;

    annotManager
      .exportAnnotations({ annotList: this._currentFileRefAnnotations })
      .then((a) => {
        this._fileRef.XFDFData = a;
        this.svcFile.UpdateFile(this._fileRef).subscribe(
          (response) => {
            return;
          },
          (error) => this.app.ShowErrorMessage(error)
        );
      });
  }

  private AddSignatureAnnotation(
    sig: Annotations.FreeHandAnnotation | Annotations.StampAnnotation
  ) {
    // CHECK SIGNATURE NOT NULL HERE
    if (!this._postingSignature) { return; }

    const { annotManager } = this._wvi;
    const ids = (sig.getCustomData('id') as string).split(';');
    const assocIds = sig.getAssociatedLinks();
    const assocs = annotManager.getAnnotationsList().filter(x => assocIds.includes(x.Id));
    annotManager.exportAnnotations({ annotList: [sig, ...assocs] }).then((x) => {
      this._annotPosition--;

      this._postingSignature.SignatureType = +sig.CustomData.type;
      if (+sig.CustomData.type === SignatureType.Initial) {
        this._postingSignature.InitialData = x;
      } else {
        this._postingSignature.SignatureData = x;
      }

      this.svcSig
        .postEnvelopeSig(
          this.envelope.EnvelopeId,
          this._fileRef.FileRefId,
          ids[0],
          ids[1],
          this._postingSignature
        )
        .subscribe(
          (response) => this.afterSignature.emit(response),
          (error) => this.app.ShowErrorMessage(error),
          () => this._postingSignature = null
        );
    });
  }

  private LoadAnnotations() {
    const { annotManager, docViewer } = this._wvi;

    // DON'T LOAD ANNOTATIONS IF ENVELOPE IS COMPLETE
    if (this.envelope.Status === EnvelopeStatus.Signed) {
      return;
    }

    // CLEAN ANNOTS AND REMOVE EVENT BEFORE LOADING
    annotManager.deleteAnnotations(this._currentFileRefAnnotations);
    this._currentFileRefAnnotations = [];
    annotManager.off('annotationChanged');

    // THERE MUST BE A VIEWER REFERENCE AND FILE WITH MARKUPS LOADED
    if (!this._wvi || !this._fileRef || !this._fileRef.XFDFData) {
      annotManager.on('annotationChanged', (annotations, action) => {
        this.SaveAnnotations(annotations, action);
      });
      return;
    }

    this.LoadFileRefAnnotations();
    this.LoadSignerAnnotations();

    annotManager.on('annotationChanged', (annotations, action) => {
      this.SaveAnnotations(annotations, action);
    });
  }

  private LoadFileRefAnnotations() {
    const { Annotations } = this._wvi;

    this._wvi.annotManager
      .importAnnotations(this._fileRef.XFDFData)
      .then((annots) => {
        this._currentFileRefAnnotations.push(...annots);

        annots.forEach((annot: Annotations.Annotation) => {
          // CUSTOMIZE STYLE OF SIG WIDGET
          (Annotations.WidgetAnnotation as any).getCustomStyles = function (
            widget: any
          ) {
            if (widget instanceof Annotations.SignatureWidgetAnnotation) {
              return { border: '1px solid #a5c7ff' };
            }
          };

          // SET OPACITY OF SIG WIDGET
          this.SetWidgetStyle(annot);

          // HIDE WIDGETS
          if (annot instanceof Annotations.WidgetAnnotation) {
            const sigIndex = this._fileRef.FileRefSignerSigs.findIndex(
              (f) =>
                (f.Signature.SignatureData || f.Signature.InitialData) &&
                `${f.SignerId};${f.SignatureId}` === annot.CustomData.id
            );
            if (!this.app.isSigner()) {

              console.log(sigIndex);
              if (sigIndex > -1) {
                annot.Hidden = true;
              } else {
                this.SetPermissions(annot);
              }
            } else {
              const mySig = annot.CustomData.id.startsWith(
                this.app.cu.SignerId
              );
              if (sigIndex > -1 || !mySig) {
                annot.Hidden = true;
              }
            }
          }
          if (
            annot instanceof Annotations.FreeTextAnnotation &&
            this.envelope.Status === EnvelopeStatus.AwaitingSignatures
          ) {
            this.SetPermissions(annot);
          }
        });
      })
      .finally(() => {
        if (this._annotNext) {
          this.Next_Field();
        } else if (this._annotPrev) {
          this.Last_Field();
        }
      });
  }

  private LoadSignerAnnotations() {
    this._fileRef.FileRefSignerSigs.forEach((sig) => {
      if (sig.Signature?.SignatureData) {
        const sigXFDF = sig.Signature.SignatureData;
        this._wvi.annotManager
          .importAnnotations(sigXFDF)
          .then((annotation: Annotations.Annotation) => {
            this.SetPermissions(annotation);
          });
      } else if (sig.Signature?.InitialData) {
        const initXFDF = sig.Signature.InitialData;
        this._wvi.annotManager
          .importAnnotations(initXFDF)
          .then((annotation: Annotations.Annotation) => {
            this.SetPermissions(annotation);
          });
      }
    });
  }

  private SetWidgetStyle(annotation: Annotations.Annotation) {
    const { Annotations, docViewer } = this._wvi;
    (Annotations.WidgetAnnotation as any).getCustomStyles(annotation);
    if (
      annotation instanceof Annotations.FreeTextAnnotation &&
      annotation.CustomData.id
    ) {
      (annotation as Annotations.FreeTextAnnotation).FillColor = new Annotations.Color(
        211,
        211,
        211,
        0.5
      );
      (annotation as Annotations.FreeTextAnnotation).FontSize = `${14.0 / docViewer.getZoom()
        }px`;
    }
  }

  private SetPermissions(annotation: Annotations.Annotation) {
    annotation.NoMove = true;
    annotation.NoResize = true;
    annotation.NoRotate = true;
    annotation.ReadOnly = true;
  }
  //#endregion
  //#region Add Field
  public AddField(type: AnnotFieldType, signer: Signer, point: any = {}) {
    const { docViewer, Annotations, annotManager, CoreControls } = this._wvi;
    const displayMode = docViewer.getDisplayModeManager().getDisplayMode();
    const pg = displayMode.getSelectedPages(point, point);
    const math = CoreControls.Math;

    if (!!point.x && pg.first == null) {
      return;
    }

    const zoom = docViewer.getZoom();
    const a = new Annotations.FreeTextAnnotation();
    a.Author = annotManager.getCurrentUser();
    a.CustomData = {
      type: `${type}`,
      fullName: `${signer.FullName}`,
      id: `${signer.Id};${GuidUtils.GenerateGuid()}`,
    };

    a.FillColor = new Annotations.Color(211, 211, 211, 0.5);
    a.FontSize = `${14.0 / zoom}px`;
    a.PageNumber = pg.first ? pg.first : docViewer.getCurrentPage();
    a.Rotation = docViewer.getCompleteRotation(a.PageNumber) * 90;
    a.StrokeColor = new Annotations.Color(0, 165, 228);
    a.StrokeThickness = 1;
    a.TextAlign = 'center';
    a.TextColor = new Annotations.Color(0, 165, 228);

    // SET WIDTH/HEIGHT OF ANNOTATION
    const width = type === AnnotFieldType.Initial ? 40.0 : 250.0;
    const height = type === AnnotFieldType.Initial ? 40.0 : 50.0;
    if (a.Rotation === 270 || a.Rotation === 90) {
      a.Width = height / zoom;
      a.Height = width / zoom;
    } else {
      a.Width = width / zoom;
      a.Height = height / zoom;
    }

    // DETERMINE LOCATION ON PAGE TO ADD
    const doc = docViewer.getDocument();
    const pgInfo = doc.getPageInfo(a.PageNumber);
    const pgPoint = displayMode.windowToPage(point, a.PageNumber);
    a.X = (pgPoint.x || pgInfo.width / 2) - a.Width / 2;
    a.Y = (pgPoint.y || pgInfo.height / 2) - a.Height / 2;

    annotManager.getAnnotationsList().forEach((annotation) => {
      if (annotation.X === a.X && annotation.Y === a.Y) {
        a.Y += 10;
        a.X += 10;
      }
    });

    a.setContents(signer.FullName);
    a.setPadding(new math.Rect(0, 0, 0, 0));

    // ADD ANNOTATION
    annotManager.deselectAllAnnotations();
    annotManager.addAnnotation(a, { imported: true });
    annotManager.redrawAnnotation(a);
    annotManager.selectAnnotation(a);
  }
  //#endregion
  //#region Jump Field Events
  public Next_Field() {
    if (!this._wvi) {
      return;
    }
    const { annotManager } = this._wvi;

    // SORT ANNOTS SO WE CAN JUMP FIELDS APPROPRIATELY
    const currSignerAnnots = this._currentFileRefAnnotations
      .filter(
        (x) =>
          x.CustomData.id?.startsWith(this.app.cu.SignerId) && x.isVisible()
      )
      .sort((a, b) => a.PageNumber - b.PageNumber || a.Y - b.Y);

    // RESET ANNOT POSITION IF FILE HAS CHANGED
    if (this._annotNext) {
      this._annotPosition = -1;
      this._annotNext = false;
    }

    // HANDLE JUMPING ANNOTATIONS
    if (currSignerAnnots[this._annotPosition + 1]) {
      this._annotPosition++;
      annotManager.jumpToAnnotation(currSignerAnnots[this._annotPosition]);
    } else {
      const currIndex = this.envelope.FileRefs.indexOf(this._fileRef);
      if (
        this.envelope.FileRefs.length > currIndex &&
        this.envelope.FileRefs[currIndex + 1]?.FileRefSignerSigs?.filter(
          (x) => x.SignerId === this.app.cu.SignerId
        )?.length > 0
      ) {
        this._annotNext = true;
        (document.getElementById(
          'frSelect'
        ) as HTMLSelectElement).selectedIndex = currIndex + 1;
        (document.getElementById(
          'frSelect'
        ) as HTMLSelectElement).dispatchEvent(new Event('change'));
      }
    }
  }

  public Last_Field() {
    if (!this._wvi) {
      return;
    }
    const { annotManager } = this._wvi;

    // SORT ANNOTS SO WE CAN JUMP FIELDS APPROPRIATELY
    const currSignerAnnots = this._currentFileRefAnnotations
      .filter(
        (x) =>
          x.CustomData.id?.startsWith(this.app.cu.SignerId) && x.isVisible()
      )
      .sort((a, b) => a.PageNumber - b.PageNumber || a.Y - b.Y);

    // RESET ANNOT POSITION IF FILE HAS CHANGED
    if (this._annotPrev) {
      this._annotPosition = currSignerAnnots.length;
      this._annotPrev = false;
    }

    // HANDLE JUMPING ANNOTATIONS
    if (currSignerAnnots[this._annotPosition - 1]) {
      this._annotPosition--;
      annotManager.jumpToAnnotation(currSignerAnnots[this._annotPosition]);
    } else {
      const currIndex = this.envelope.FileRefs.indexOf(this._fileRef);
      if (
        currIndex > 0 &&
        this.envelope.FileRefs[currIndex - 1]?.FileRefSignerSigs?.filter(
          (x) => x.SignerId === this.app.cu.SignerId
        )?.length > 0
      ) {
        this._annotPrev = true;
        (document.getElementById(
          'frSelect'
        ) as HTMLSelectElement).selectedIndex = currIndex - 1;
        (document.getElementById(
          'frSelect'
        ) as HTMLSelectElement).dispatchEvent(new Event('change'));
      }
    }
  }
  //#endregion
  //#region Drag Events
  private Drag_Drop(e: DragEvent) {
    const { docViewer } = this._wvi;
    const scrollElement = docViewer.getScrollViewElement();
    const scrollLeft = scrollElement.scrollLeft || 0;
    const scrollTop = scrollElement.scrollTop || 0;

    this._dropPoint.x = e.pageX + scrollLeft;
    this._dropPoint.y = e.pageY + scrollTop;

    e.preventDefault();
  }

  public Touch_Started(e: TouchEvent) {
    // const target = e.target as HTMLDivElement;
    // target.style.opacity = '0.5';

    // const copy = target.cloneNode(true) as HTMLDivElement;
    // copy.id = 'form-build-drag-image-copy';
    // copy.style.width = '250px';

    // document.body.appendChild(copy);
    e.preventDefault();
  }

  public Touch_End(e: TouchEvent, signer: Signer, type: AnnotFieldType) {
    // GET X,Y OF TOUCH END IN RELATION TO SCREEN AND ADJUST FOR SCROLL
    const parentScrollX = document.scrollingElement.scrollLeft;
    const parentScrollY = document.scrollingElement.scrollTop;
    const px = e.changedTouches[0].pageX - parentScrollX;
    const py = e.changedTouches[0].pageY - parentScrollY;

    // STOP IMMEDIATELY IF DROPPING ANYWHERE OTHER THAN IN IFRAME ELEMENT
    if (!(document.elementFromPoint(px, py) instanceof HTMLIFrameElement)) {
      this.AddField(type, signer);
      return;
    }

    const { docViewer } = this._wvi;

    // CALCULATE DROP POINT WITHIN DOCUMENT WINDOW ADJUSTED FOR INNER SCROLL
    const viewer = document.elementFromPoint(px, py) as HTMLIFrameElement;
    const viewerPos = viewer.getBoundingClientRect();
    const scrollElement = docViewer.getScrollViewElement();
    const scrollX = scrollElement.scrollLeft || 0;
    const scrollY = scrollElement.scrollTop || 0;
    this._dropPoint.x = px - viewerPos.x + scrollX;
    this._dropPoint.y = py - viewerPos.y + scrollY;

    // GET HEADER ELEMENTS FROM VIEWER WINDOW FOR OUT OF BOUNDS CHECKING
    let addedHeight = 0;
    const headerElements = viewer.contentWindow.document.getElementsByClassName(
      'Header'
    );
    const headerToolsElements = viewer.contentWindow.document.getElementsByClassName(
      'HeaderToolsContainer'
    );
    addedHeight +=
      headerElements.length > 0 ? headerElements[0].clientHeight : 0;
    addedHeight +=
      headerToolsElements.length > 0 ? headerToolsElements[0].clientHeight : 0;

    // BAD DROP POINT - 0 SCROLL AND TRIED TO DROP ON HEADER ELEMENT
    if (this._dropPoint.x <= 0 || this._dropPoint.y - addedHeight <= 0) {
      return;
    }

    // FINALLY ADD FIELD
    this.AddField(type, signer, this._dropPoint);
    e.preventDefault();
  }

  public Drag_Started(e: DragEvent) {
    const target = e.target as HTMLDivElement;
    target.style.opacity = '0.5';

    const copy = target.cloneNode(true) as HTMLDivElement;
    copy.id = 'form-build-drag-image-copy';
    copy.style.width = '250px';

    document.body.appendChild(copy);
    e.dataTransfer.setDragImage(copy, 125, 25);
    e.dataTransfer.setData('text', '');
  }

  public Drag_End(e: DragEvent, signer: Signer, type: AnnotFieldType) {
    const target = e.target as HTMLDivElement;
    target.style.opacity = '1.0';

    this.AddField(type, signer, this._dropPoint);
    document.body.removeChild(
      document.getElementById('form-build-drag-image-copy')
    );
    e.preventDefault();
  }
  //#endregion

  public getFileIndexString(): string {
    const fileIndex = this.envelope.FileRefs.findIndex(
      (x) => x.Filename === this._fileRef.Filename
    );

    return `File ${fileIndex + 1} of ${this.envelope.FileRefs.length}`;
  }
}
