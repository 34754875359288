import { RoleType, RoleUtils } from './../../services/auth.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ContractService } from '../../services/contract.service';
import { AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { ContractAction, ContractActionType } from '../../utils/form/ContractActionUtils';
import { Contract, ContractType } from '../../classes/contract';
import { map } from 'rxjs/operators';
import { AppLayoutComponent } from '../../layout/app-layout/app-layout.component';
import { SearchPipe } from './contract-list.pipe';
import ContractFormUtils from '../../utils/form/ContractFormUtils';

@Component({
  selector: 'app-upload-no-qr-wizard',
  templateUrl: './upload-no-qr-wizard.component.html',
})
export class UploadNoQrWizardComponent implements OnInit {
  @Input() pdf = true;
  @Input() cid = null;
  @Input() exp = false;
  @Output() action = new EventEmitter<[ContractActionType, boolean]>();
  @Output() contractSelected = new EventEmitter<Contract>();

  form = this.fb.group({
    Q0: null,
    Q1: null,
    Q2: null,
    ContractId: null
  });
  q0: AbstractControl;
  q1: AbstractControl;
  q2: AbstractControl;
  contractId: AbstractControl;
  ct = ContractType;
  contract = null;
  paperActions: ContractAction[] = [];

  types: string[] = ['Buyer', 'Seller', 'Offer'];
  showError = false;

  contractActionsCache = new Map<string, ContractAction[]>();
  contracts: Observable<Contract[]>;

  contractActions: ContractAction[] = [];
  cfu = ContractFormUtils;

  customSearchFn = (term: string, item: Contract) => new SearchPipe().transform(item).includes(term.replace(/ /g, '').toLowerCase());

  constructor(private svc: ContractService, private fb: UntypedFormBuilder, private app: AppLayoutComponent) {
    this.q0 = this.form.get('Q0');
    this.q1 = this.form.get('Q1');
    this.q2 = this.form.get('Q2');
    this.contractId = this.form.get('ContractId');
  }

  ngOnInit() {
    this.contracts = this.svc.GetAllContractsSearch(true, true);

    // ONLY PDF'S REQUIRE ASKING THE FIRST TWO QUESTIONS
    if (!this.pdf) {
      this.q0.setValue(false);
      this.q1.setValue(false);
    }

    if (this.cid) {
      this.contracts.pipe(map((data: Contract[]) =>
        data.find(x => x.ContractId === this.cid || x.Number.toString() === this.app.contractNumber))).subscribe(x => {
          if (x) {
            this.contractId.setValue(x.ContractId);
            x.ContractId = this.cid;
          }
          this.contract = x;
          this.contractSelected.emit(x);
          this.action.emit([ContractActionType.AttachmentUpload, null]);
        });
    }
  }

  public IsSellerOnly(): boolean {
    return RoleUtils.HasRole(this.app.cu.User.UserType, RoleType.ListingAgent);
  }

  public Q0_OnChange() {
    this.action.emit(null);
    this.q1.setValue(null);
    this.q2.setValue(null);
    this.contractId.setValue(null);
    this.contract = null;
  }

  public Q1_OnChange() {
    if (this.q1.value === true) {
      this.action.emit(null);
      this.contractId.setValue(null);
      this.contract = null;
    } else if (!this.cid) {
      this.action.emit(null);
      this.q2.setValue(null);
    }
  }

  public Q2_OnChange() {
    if (this.q2.value === ContractType.Buyer) {
      this.action.emit([ContractActionType.NewBuyerUpload, null]);
    } else if (this.q2.value === ContractType.Listing) {
      this.action.emit([ContractActionType.NewSellerUpload, null]);
    } else {
      this.action.emit([ContractActionType.AcceptedPaperOfferUpload, null]);
    }
  }

  public contract_OnChange(value: Contract) {
    this.contract = value;
    if (value) {
      if (this.paperActions.length === 0) {
        this.contractSelected.emit(this.contract);
        this.action.emit([ContractActionType.AttachmentUpload, null]);
        if (this.contractActionsCache.has(value.ContractId)) {
          this.contractActions = this.contractActionsCache.get(value.ContractId);
        } else {
          this.svc.GetActionsForContract(value.ContractId).subscribe({
            next: (data: ContractAction[]) => {
              this.contractActionsCache.set(value.ContractId, data);
              this.contractActions = data;
            }
          });
        }
      } else {
        this.action.emit(null);
      }
    } else {
      this.action.emit(null);
      this.contractSelected.emit(null);
      this.contractActions = [];
    }
  }

  public paperActions_OnChange(value: [ContractActionType, boolean]) {
    this.contractSelected.emit(this.contract);
    this.action.emit(value);
  }
}
