<div *ngIf="!modal">
    <div [innerHTML]="config.Message"></div>

    <div *ngIf="config.Textbox" class="form-row" style="padding-top: 20px">
        <div class="form-group col-12">
            {{config.TextboxLabel}}
            <textarea [(ngModel)]="config.TextboxData" class="form-control" autocomplete="off" maxlength="500" rows="3">
        </textarea>
        </div>
    </div>
    <div class="form-row pt-3">
        <div class="form-group col-6 mb-0">
            <button type="button" class="btn {{ config.NoButtonCss }} btn-block" (click)="selected.emit(false)">
                {{ config.NoButtonText }}
            </button>
        </div>
        <div class="form-group col-6 mb-0">
            <button type="button" class="btn {{ config.YesButtonCss }} btn-block" (click)="selected.emit(true)">
                {{ config.YesButtonText }}
            </button>
        </div>
    </div>
</div>
<ng-template #template>
    <div class="modal-header bg-primary p-0">
        <div class="col p-2">
          <h6 class="text-light m-0">Confirmation Prompt</h6>
        </div>
        <div class="col-auto p-0">
          <span class="align-middle">
            <button type="button" class="btn btn-danger btn-block" (click)="no()">
              <fa-icon [icon]="['fas', 'times']"></fa-icon>
            </button>
          </span>
        </div>
      </div>
    <div class="modal-body">
        <div [innerHTML]="config.Message"></div>
        <div *ngIf="config.Textbox" class="form-row" style="padding-top: 20px">
            <div class="form-group col-12">
                {{config.TextboxLabel}}
                <textarea [(ngModel)]="config.TextboxData" class="form-control" autocomplete="off" maxlength="500"
                    rows="3">
            </textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-auto p-0">
            <button type="button" class="btn {{ config.NoButtonCss }}" (click)="no()">
                {{ config.NoButtonText }}
            </button>
        </div>
        <div class="col"></div>
        <div class="col-auto p-0">
            <button type="button" class="btn {{ config.YesButtonCss }}" (click)="yes()">
                {{ config.YesButtonText }}
            </button>
        </div>
    </div>
</ng-template>