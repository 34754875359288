<form *ngIf="contractActions?.length > 0" [formGroup]="form">
    <div class="form-row">
        <div class="form-group col">
            Select an action to continue:
            <select name="selCount" class="form-control" formControlName="SelectedAction" autocomplete="off"
                (change)="OptionSelected($any($event).target.value)">
                <option *ngFor="let action of contractActions" value="{{action.Id}}">
                    {{ action.Name ? action.Name : action.ActionType.Description }}
                </option>
                <option value="{{cat.AttachmentUpload}}">Other</option>
            </select>
        </div>
    </div>
</form>