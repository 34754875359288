import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PageViewModeType } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'app-pdf-lite-viewer',
  template: `
  <div *ngIf="modal">
    <div class="modal-header bg-primary p-0">
      <div class="col p-2">
        <h6 class="text-light m-0">{{ filenameNoExtension }}</h6>
      </div>
      <div class="col-auto p-0">
        <span class="align-middle">
          <button type="button" class="btn btn-danger btn-block" (click)="modal.hide()">
            <fa-icon [icon]="['fas', 'times']"></fa-icon>
          </button>
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="_blob !== null" [ngClass]="!modal ? 'pb-3' : ''" class="no-print">
    <ngx-extended-pdf-viewer
      [src]="_blob"
      [filenameForDownload]="_filename"
      [pageViewMode]="_pageViewMode"
      [textLayer]="true"
      [zoom]="'page-width'"
      [showBookmarkButton]="false"
      [showOpenFileButton]="false"
      [showPresentationModeButton]="true"
      [showPrintButton]="showPrintButton"
      [showPropertiesButton]="false"
      [showRotateButton]="false"
      [showDownloadButton]="showDownloadButton"
      (pdfLoaded)="afterLoadComplete.emit();"
      [height]="'600px'"
      [ignoreKeyboard]="true"
      ></ngx-extended-pdf-viewer>
  </div>
  `
})
export class PDFLiteViewerComponent {

  public _filename: string = null;
  public _blob: Blob = null;
  public _pageViewMode: PageViewModeType = 'multiple';

  //#region Variables
  @Input() modal: BsModalRef = null;
  @Input() showDownloadButton: boolean = true;
  @Input() showPrintButton: boolean = false;
  @Input() set filename(value: string) {
    if (value && !value.toLowerCase().endsWith('.pdf')) { value += '.pdf'; }
    this._filename = value;
  }
  @Input() set infiniteScroll(value: boolean) {
    this._pageViewMode = (value == true) ? 'infinite-scroll' : 'multiple';
  }

  get filename(): string { return this._filename ? this._filename : 'Download.pdf'; }
  get filenameNoExtension(): string { return this.filename.slice(0, this.filename.length - 4); }

  @Input() set pdf(value: ArrayBuffer) {
    this.OpenFile(value);
  }
  @Output() afterLoadComplete = new EventEmitter();
  @Output() error = new EventEmitter<any>();
  //#endregion

  private OpenFile(data: ArrayBuffer = null) {
    if (!data) {
      this._blob = null;
      return;
    }

    this._blob = new Blob([new Uint8Array(data)], { type: 'application/pdf' });
  }
  //#endregion
}
