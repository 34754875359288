<div class="card" [ngClass]="{'hover': hover, 'disabledtile': IsTileDisabled()}" (click)="selected.emit(contract);">
  <div class="card-body">
    <div class="form-row">
      <div class="col">
        <h5 class="card-title mb-0">
          {{ _fu.ConcatOwnerNames(contract.ContractOwners, false) }}
        </h5>
      </div>
      <div *ngIf="df && df.GetActions().length > 0" class="col-auto">
        <div class="d-none d-sm-block pr-0 position-absolute" style="left: -78px">
          <div ngbDropdown placement="bottom-right" class="d-inline-block">
            <button class="btn btn-primary dropdown-toggle" ngbDropdownToggle>Actions</button>
            <div ngbDropdownMenu>
              <button *ngFor="let action of df.GetActions()" ngbDropdownItem
                (click)="click.emit(action.ActionType.Id)">{{ action.Name ? action.Name : action.ActionType.Name }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-auto d-sm-none pr-0">
          <div ngbDropdown placement="bottom-right" class="d-inline-block" style="width: inherit">
            <button class="btn btn-dropdown dropdown-toggle dropdownButton" id="dropdown" ngbDropdownToggle>
              <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdown">
              <button *ngFor="let action of df.GetActions()" class="dropdown-item pointerCursor"
                (click)="click.emit(action.ActionType.Id)">{{ action.Name ? action.Name : action.ActionType.Name
                }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p *ngIf="reportType === _rt.Buyers" class="card-text mb-0">
      <span>
        <b [ngClass]="_cfu.getContractSignClass(contract.Status)">{{ _cfu.GetContractStatusByContract(contract) }}</b>
        {{ _cfu.getSubStatus(contract.SubStatus) === '' ? '' : ' |'}}
      </span>
      <span *ngIf="contract.SubStatus">
        <b>{{ _cfu.getSubStatus(contract.SubStatus) | uppercase }}</b>
      </span>
      <span *ngIf="(contract.Prop | buyerType) != null">
        | <b>{{ contract.Prop | buyerType }}</b>
      </span>
    </p>
    <p *ngIf="contract.AgreementStart && contract.AgreementEnd" class="card-text mb-0">
      {{ (contract.AgreementStart | newDate) }} <b>to</b> {{ (contract.AgreementEnd | newDate ) }}
    </p>
    <div *ngIf="hasOffer" class="alert alert-warning alert-slim">
      WARNING: This buyer is involved in an active offer.
    </div>

    <div *ngIf="IsTileDisabled()" class="alert alert-warning alert-slim">
      WARNING: This buyer cannot be selected until
      {{ contract.AgreementStart | newDate:'MMM d' }} at {{ contract.AgreementStart | newDate:'h:mm a' }}.
    </div>
  </div>
</div>