<div id="divResetDemo" class="form-row" *ngIf="app.isAdmin() && cu.isDemoEnv()">
  <div class="form-group col">
    <button type="button" class="btn btn-block btn-primary" (click)="ResetDemo()">
      <fa-icon [icon]="['fas', 'undo-alt']"></fa-icon>&nbsp;Reset Demo
    </button>
  </div>
</div>
<div id="divUserSearch">
  <div class="form-group">
    <app-search-user (selected)="user_Selected($event);"></app-search-user>
  </div>
</div>