<div *ngIf="modal">
    <div class="modal-header bg-primary p-0">
        <div class="col p-2">
            <h6 class="text-light m-0">{{ _filename }}</h6>
        </div>
        <div class="col-auto p-0">
            <span class="align-middle">
                <button type="button" class="btn btn-danger btn-block" (click)="modal.hide()">
                    <fa-icon [icon]="['fas', 'times']"></fa-icon>
                </button>
            </span>
        </div>
    </div>
</div>
<div class="form-row">
    <div [ngClass]="{'col-md-3' : envelope.Status === _status.InProgress }" class="col-12">
        <div *ngIf="!app.isSigner()" class="form-row">
            <div class="col-auto"> <b>{{ getFileIndexString() }}</b> </div>
        </div>
        <div class="form-row pb-1">
            <div class="col">
                <select id="frSelect" class="form-control" autocomplete="new-password"
                    (change)="OpenFileRef($any($event).target.value)">
                    <option *ngFor="let file of envelope.FileRefs" value="{{file.FileRefId}}">
                        {{ file.Filename }}
                    </option>
                </select>
            </div>
            <h3 *ngIf="app.isSigner()" class="col-sm-auto">
                <div class="badge badge-secondary p-2">{{ getFileIndexString() }}</div>
            </h3>
            <div *ngIf="app.isSigner()" class="col-auto">
                <button type="button" class="btn btn-block btn-primary" (click)="Last_Field()">
                    Prev Sig
                </button>
            </div>
            <div *ngIf="app.isSigner()" class="col-auto">
                <button type="button" class="btn btn-block btn-primary" (click)="Next_Field()">
                    Next Sig
                </button>
            </div>
        </div>
        <div *ngIf="envelope.Status === _status.InProgress">
            <div *ngFor="let signer of envelope.Signers" class="form-row pb-1">
                <div class="col-12 font-weight-bold">
                    {{ signer.FullName }}
                </div>
                <div class="col-7" draggable="true" (touchstart)="Touch_Started($event)"
                    (dragstart)="Drag_Started($event)" (dragend)="Drag_End($event, signer, _type.Sign)"
                    (touchend)="Touch_End($event, signer, _type.Sign)">
                    <button type="button" class="btn btn-block btn-primary" (click)="AddField(_type.Sign, signer)">
                        Signature
                    </button>
                </div>
                <div class="col-5" draggable="true" (touchstart)="Touch_Started($event)"
                    (dragstart)="Drag_Started($event)" (dragend)="Drag_End($event, signer, _type.Initial)"
                    (touchend)="Touch_End($event, signer, _type.Initial)">
                    <button type="button" class="btn btn-block btn-outline-primary"
                        (click)="AddField(_type.Initial, signer)">
                        Initial
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div [ngClass]="{'col-md-9' : envelope.Status === _status.InProgress }" class="col-12">
        <div #viewer [ngClass]="!modal ? 'pb-3' : ''" style="height: 600px;"></div>
    </div>
    <app-signature-modal [showModal]="_showSigModal" [fileRefId]="_fileRef.FileRefId" [parentId]="envelope.EnvelopeId"
        [signerId]="envelope.Signers[0].Id" [fullName]="envelope.Signers[0].FullName"
        (signature)="HandleSignature($event);"></app-signature-modal>
</div>