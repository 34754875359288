<div class="form-row">
  <div class="form-group col-12">
    <input [(ngModel)]="search" name="search" type="text" class="form-control" placeholder="Search for a brokerage..."
      autocomplete="off" (keyup)="searchText.next($any($event).target.value)" maxlength="30" />
  </div>
</div>

<div *ngIf="!pair" class="form-row">
  <div class="form-group col-12 mb-0" *ngFor="let brokerage of brokerages |
  paginate: { itemsPerPage: 5, currentPage: pg }; let i = index">
    <app-brokerage-tile [data]="brokerage" [group]="groupAnimations" (selected)="brokerage_Selected($event)">
    </app-brokerage-tile>
  </div>
</div>

<div *ngIf="pair" class="form-row">
  <div class="form-group col-12 mb-0" *ngFor="let pair of brokerages | pair |
  paginate: { itemsPerPage: 5, currentPage: pg }; let i = index">
    <div class="form-row">
      <div [ngClass]="_smallScreen ? 'col-12' : 'col-6' ">
        <app-brokerage-tile [data]="pair[0]" [group]="groupAnimations" (selected)="brokerage_Selected($event)">
        </app-brokerage-tile>
      </div>
      <div [ngClass]="_smallScreen ? 'col-12' : 'col-6'" *ngIf="pair[1] != null && pair[1] != undefined">
        <app-brokerage-tile [data]="pair[1]" [group]="groupAnimations" (selected)="brokerage_Selected($event)">
        </app-brokerage-tile>
      </div>
    </div>
  </div>
</div>

<div class="text-center" *ngIf="brokerages && brokerages.length > 5">
  <pagination-controls (pageChange)="pg = $event"></pagination-controls>
</div>

<div class="form-group" *ngIf="count === 0">
  <div class="alert alert-warning" role="alert">
    <div class="col">
      The brokerage you were looking for was not found.
    </div>
  </div>
</div>