<div class="card" style="min-height: 124px" (click)="selected.emit(data);" [ngClass]="clickable ? 'hover': ''" [ngStyle]="{'cursor': clickable ? 'pointer' : 'auto'}">
    <div class="card-body">
      <div class="form-row">
        <div class="col">
          <h5 class="card-title mb-1">
            {{ data.FirstName }} {{data.LastName }}
          </h5>
          <p class="card-text mb-0">
            {{ data.Brokerage.Name }}
          </p>
          <div *ngIf="data.MobilePhoneNumber" class="form-row">
            <div class="col-auto" style="width: 30px">
              <fa-icon [icon]="['fas', 'phone']"></fa-icon>
            </div>
            <div class="col">
              {{data.MobilePhoneNumber}}
            </div>
          </div>
          <div *ngIf="data.EmailAddress" class="form-row">
            <div class="col-auto" style="width: 30px">
              <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
            </div>
            <div class="col">
              {{data.EmailAddress}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>