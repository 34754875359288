import { CopyFileModalComponent } from './copy-file-modal/copy-file-modal.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbButtonsModule, NgbDropdownModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { ngfModule } from 'angular-file';
import { NgxPaginationModule } from 'ngx-pagination';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { AttachmentAcceptedPaperOfferComponent } from './attachment-accepted-paper-offer/attachment-accepted-paper-offer.component';
import { AttachmentPaperOfferAmendmentWizardComponent } from './attachment-paper-offer-amendment-wizard/attachment-paper-offer-amendment-wizard.component';
import { AttachmentPaperOfferWizardComponent } from './attachment-paper-offer-wizard/attachment-paper-offer-wizard.component';
import { AttachmentPaperPreOfferWizardComponent } from './attachment-paper-pre-offer-wizard/attachment-paper-pre-offer-wizard.component';
import { AttachmentPaperSignatureWizardComponent } from './attachment-paper-signature-wizard/attachment-paper-signature-wizard.component';
import { AttachmentSelectPaperActionComponent } from './attachment-select-paper-action/attachment-select-paper-action.component';
import { AttachmentUploadMainComponent } from './attachment-upload-main/attachment-upload-main.component';
import { AttachmentUploadComponent } from './attachment-upload/attachment-upload.component';
import { AttachmentWizardComponent } from './attachment-wizard/attachment-wizard.component';
import { UploadWizardComponent } from './upload-wizard/upload-wizard.component';
import { UploadNoQrWizardComponent } from './upload-no-qr-wizard/upload-no-qr-wizard.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { FileService } from '../services/file.service';
import { AttachmentPaperOfferSellerNoticeWizardComponent } from './attachment-paper-offer-seller-notice-wizard/attachment-paper-offer-seller-notice-wizard.component';
import { AddressPipe, OwnersPipe, PricePipe, SearchPipe, StatusPipe } from './upload-no-qr-wizard/contract-list.pipe';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    NgbButtonsModule,
    NgbDropdownModule,
    NgbProgressbarModule,
    NgSelectModule,
    NgOptionHighlightModule,
    ngfModule,
    NgxPaginationModule,
    PipesModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  declarations: [
    AttachmentAcceptedPaperOfferComponent,
    AttachmentPaperOfferAmendmentWizardComponent,
    AttachmentPaperOfferWizardComponent,
    AttachmentPaperPreOfferWizardComponent,
    AttachmentPaperSignatureWizardComponent,
    AttachmentSelectPaperActionComponent,
    AttachmentUploadComponent,
    AttachmentUploadMainComponent,
    AttachmentWizardComponent,
    UploadWizardComponent,
    UploadNoQrWizardComponent,
    AttachmentPaperOfferSellerNoticeWizardComponent,
    CopyFileModalComponent,
    OwnersPipe,
    AddressPipe,
    PricePipe,
    SearchPipe,
    StatusPipe
  ],
  exports: [
    AttachmentUploadComponent,
    AttachmentUploadMainComponent,
    UploadNoQrWizardComponent,
    UploadWizardComponent,
    CopyFileModalComponent
  ],
  providers: [
    FileService
  ]
})
export class AttachmentModule { }
