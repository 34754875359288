<div class="row justify-content-md-center">
    <div class="col-lg-6 col-md-8 col-12">
        <div *ngIf="!(site._maintenanceWindow | maintenance)">
            <div class="alert alert-warning"><b>DealSimple™</b> is currently undergoing <b>maintenance</b> and will be
                back up
                at <b>{{ site._maintenanceWindow.end | maintenanceDate }}</b>. Thank you for your patience.</div>
        </div>
        <form *ngIf="site._maintenanceWindow | maintenance" [formGroup]="frm" (ngSubmit)="Login()" novalidate>
            <!-- FORM LEVEL VALIDATION -->
            <div class="alert alert-danger" role="alert" *ngIf="!success && frm.status === 'INVALID'">
                <div *ngIf="!frm.get('EmailAddress').valid">
                    <span *ngIf="frm.get('EmailAddress').errors['required']">
                        <b>Email Address</b> is required</span>
                    <span *ngIf="frm.get('EmailAddress').errors['pattern']">
                        <b>Email Address</b> is invalid</span>
                </div>
                <div *ngIf="!frm.get('Password').valid">
                    <span *ngIf="frm.get('Password').errors['required']">
                        <b>Password</b> is required</span>
                    <span *ngIf="frm.get('Password').errors['minlength']">
                        <b>Password</b> must be at least 8 characters long</span>
                </div>
                <div *ngIf="!frm.get('PassCode').valid">
                    <span *ngIf="frm.get('PassCode').errors['required']">
                        <b>Passcode</b> is required</span>
                    <span *ngIf="frm.get('PassCode').errors['minlength']">
                        <b>Passcode</b> is too short</span>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col">
                    <input id="EmailAddress" type="email" class="form-control" formControlName="EmailAddress"
                        placeholder="Email Address" maxlength="50">
                </div>
            </div>

            <div class="form-row">
                <div class="col">
                    <!-- <input id="Password" type="password" class="form-control" minlength="8" maxlength="40"
                        placeholder="Password" autocomplete="new-password" formControlName="Password"> -->
                    <app-password-form-input [ParentForm]="frm" [GivenFormControlName]="'Password'"
                        [GivenId]="'Password'"></app-password-form-input>
                </div>
            </div>

            <div class="form-row form-group forgot-pass">
                <a class="alt-link pl-2" routerLink="/login/password.recovery">Forgot
                    Password?</a>
            </div>

            <div class="form-row">
                <div class="form-group col">
                    <button type="submit" class="btn btn-primary btn-lg btn-block">Login</button>
                </div>
            </div>
            <div class="form-row">
                <button type="button" class="btn btn-outline-secondary text-center"
                    routerLink="/pricing">Register</button>
            </div>
        </form>
    </div>
</div>